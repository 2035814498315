import React from 'react';
import { cleanUpHtml } from '../../utils/string-tools';
import { Typography, useTheme } from '@mui/material';
import { getLineHighlight } from '../../styles/theme';

type Props = {
	text: any;
	variantSize: any;
	rotate: boolean;
	sx?: any;
};

const HighlightedTextWysiwyg = ({ text, variantSize, rotate, sx }: Props) => {
	const { palette } = useTheme();
	return (
		<Typography
			variant={variantSize}
			component={'div'}
			sx={{
				display: 'inline-block',
				color: palette.primary.main,
				'& *': {
					position: 'relative',
					m: '0',
					p: '0',
					transform: rotate ? 'rotate(-4deg)' : '',
				},
				'& *::after': {
					display: 'inline-block',
					content: "''",
					left: '-20px',
					right: '0',
					top: '6%',
					bottom: '0',
					position: 'absolute',
					backgroundImage: `url(${getLineHighlight().long})`,
					backgroundRepeat: 'round',
					backgroundPosition: '0 0',
					height: '90%',
					width: '110%',
					zIndex: -1,
				},
				...sx,
			}}
			dangerouslySetInnerHTML={{
				__html: text ? cleanUpHtml(text) : '',
			}}
		/>
	);
};

export default HighlightedTextWysiwyg;
