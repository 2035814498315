import { Grid, useTheme } from "@mui/material";

type Props = {
  children: any;
  sx?: any;
  justify: string;
  smSize?: number;
  lgSize: number;
  xlSize?: number;
  withPadding: boolean;
};

const BlockWrapper = ({
  children,
  sx,
  justify,
  smSize,
  lgSize,
  xlSize,
  withPadding,
}: Props) => {
  const theme = useTheme();

  return (
    <Grid
      container
      item
      justifyContent={justify}
      component={"section"}
      xs={12}
      sm={smSize && smSize}
      lg={lgSize}
      xl={xlSize}
      m={"0 auto"}
      sx={{
        p: withPadding ? "40px" : "",
        [theme.breakpoints.down("md")]: {
          p: withPadding ? "40px 26px 40px 26px" : "",
        },
        ...sx,
      }}
    >
      {children}
    </Grid>
  );
};

export default BlockWrapper;
