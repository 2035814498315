import { Grid, Tab, Tabs } from '@mui/material';
import { useContext, useState } from 'react';
import AuthContext from '../../store/auth-context';
import FirstStageTable from './StagesTables/FirstStageTable';
import OlympicsContext from '../../store/olympics-context';
import SecondAStageTable from './StagesTables/SecondAStageTable';
import SecondBStageTable from './StagesTables/SecondBStageTable';
import ThirdAStageTable from './StagesTables/ThirdAStageTable';
import ThirdBStageTable from './StagesTables/ThirdBStageTable';

export const filter = (list: any[], value: any) => {
	return list.filter((i) => {
		const val = Number(value);
		if (!isNaN(val) && value > 0) {
			return i.district.id == val;
		} else {
			return true;
		}
	});
};

export type StageOption = {
	key: number;
	title: string;
};

const StagesTable = () => {
	const { olympics } = useContext(OlympicsContext);
	const [tab, setTab] = useState(0);
	const { isAdmin } = useContext(AuthContext);
	const showStageThirdA = isAdmin || olympics?.thirdAStagePublished;
	const showStageThirdB = isAdmin || olympics?.thirdBStagePublished;
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	return (
		<>
			<Grid
				item
				container
				justifyContent={'space-between'}
				sx={{
					borderBottom: 1,
					mt: 3,
					borderColor: 'divider',
				}}
			>
				<Tabs
					value={tab}
					onChange={handleTabChange}
				>
					<Tab label='Etap I' />
					<Tab label='Etap IIA' />
					<Tab label='Etap IIB' />
					{showStageThirdA && <Tab label='Etap IIIA' />}
					{showStageThirdB && <Tab label='Etap IIIB' />}
				</Tabs>
			</Grid>
			{tab === 0 && <FirstStageTable />}
			{tab === 1 && <SecondAStageTable />}
			{tab === 2 && <SecondBStageTable />}
			{tab === 3 && <ThirdAStageTable />}
			{tab === 4 && <ThirdBStageTable />}
		</>
	);
};

export default StagesTable;
