import { Box, useTheme } from '@mui/material';
import BlockWrapper from '../components/BlockWrapper';
import Wysiwyg from '../components/UI/Wysiwyg';

const CentredText = ({ content }: any) => {
	const { palette, typography } = useTheme();

	return (
		<BlockWrapper
			smSize={10}
			lgSize={6}
			xlSize={6}
			justify={'center'}
			withPadding={false}
			sx={{
				flexDirection: 'column',
				alignItems: 'flex-start',
				padding: { xs: '0 26px', md: '' },
			}}
		>
			<Box
				display='flex'
				flexDirection='column'
				alignItems='center'
				justifyContent='center'
				paddingBottom='60px'
			>
				<Wysiwyg
					text={content.desc}
					fontWeight={'400'}
					sx={{
						fontSize: typography.h5.fontSize,
						letterSpacing: '0.18px',
						'& *': { display: '', m: '20px auto' },
						'& ul': {
							padding: '',
						},
						'& li': {
							margin: '20px 0 20px -15px',
						},
						'& a': {
							color: palette.primary.main,
							textDecorationThickness: '1px',
						},
					}}
				/>
			</Box>
		</BlockWrapper>
	);
};

export default CentredText;
