import Districts from '../model/user/Districts';
import Schools from '../model/user/Schools';
import Specializations from '../model/user/Specializations';
import Teachers from '../model/user/Teachers';
import axios from 'axios';

export async function getDistricts() {
	const response = await fetch(process.env.REACT_APP_API_URL + '/olympics/districts');
	const data = await response.json();
	return Districts.fromApiResponse(data);
}

export async function getSchools() {
	const response = await fetch(process.env.REACT_APP_API_URL + '/olympics/schools');
	const data = await response.json();
	return Schools.fromApiResponse(data);
}

export async function getSpecializations() {
	const response = await fetch(process.env.REACT_APP_API_URL + '/olympics/specializations');
	const data = await response.json();
	return Specializations.fromApiResponse(data);
}

export async function getTeachersByDistrictId(id: string) {
	const response = await fetch(process.env.REACT_APP_API_URL + `/teachers/filter?districtIds[]=${id}`);
	const data = await response.json();
	return Teachers.fromApiResponse(data);
}

export async function getAllTeachers() {
	const response = await fetch(process.env.REACT_APP_API_URL + '/teachers/filter');
	const data = await response.json();
	return Teachers.fromApiResponse(data);
}

export const getUserData = async (token: string | null) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/me' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.get(url, config);
};

export const sendCertificateData= async (token: string | null, data: any) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/students/certificate/data' : '';
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await axios.post(url, data, config);
};
