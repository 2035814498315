import { Box, Grid, Typography, useTheme } from '@mui/material';
import { getLineHighlight } from '../styles/theme';
import Wysiwyg from '../components/UI/Wysiwyg';

type Props = {
	content: any;
};
const ExtendedContactInfo = ({ content }: Props) => {
	const { palette } = useTheme();

	return (
		<Grid
			container
			item
			flexDirection={'column'}
			alignItems={'center'}
			xs={12}
			justifyContent={{ xs: 'center', lg: 'left' }}
			sx={{
				padding: '0 40px',
				color: palette.primary.main,
				marginBottom: '80px',
			}}
		>
			<Grid
				item
				container
				flexDirection={'column'}
				alignItems={'start'}
			>
				{content?.contact_line &&
					content?.contact_line.length > 0 &&
					content?.contact_line.map((c: any) => {
						return (
							<>
								<Typography
									sx={{
										zIndex: 2,
										position: 'relative',
										fontSize: '24px',
										fontWeight: 500,
										marginLeft: '20px',
										marginBottom: '22px',
										'&::after': {
											display: 'inline-block',
											content: "''",
											left: '-20px',
											right: '0',
											top: '0',
											bottom: '0',
											position: 'absolute',
											backgroundImage: `url(${getLineHighlight().long})`,
											backgroundSize: '100% 80%',
											backgroundRepeat: 'no-repeat',
											backgroundPosition: 'center',
											width: '110%',
											zIndex: -1,
										},
									}}
								>
									{c.contact_name}
								</Typography>
								{c.contact_comment && (
									<Typography
										sx={{
											fontSize: '24px',
											marginBottom: '22px',
										}}
									>
										{c.contact_comment}
									</Typography>
								)}
								<Typography
									sx={{
										fontSize: '24px',
										fontWeight: 500,
										marginBottom: '64px',
									}}
								>
									E-mail:{' '}
									<Box
										component={'a'}
										href={`mailto:${c.contact_email}`}
										sx={{
											color: palette.primary.main,
										}}
									>
										{c.contact_email}
									</Box>
								</Typography>
							</>
						);
					})}
			</Grid>
			{content?.contact_title && (
				<Typography
					sx={{
						fontSize: '32px',
						fontWeight: 500,
						marginTop: '148px',
						marginBottom: '48px',
					}}
				>
					{content?.contact_title}
				</Typography>
			)}
			<Grid
				item
				container
				justifyContent={'space-between'}
				rowGap={'80px'}
				columnGap={'20px'}
			>
				{content?.district_contact_info &&
					content?.district_contact_info.length > 0 &&
					content?.district_contact_info.map((c: any) => {
						return (
							<Grid
								item
								container
								flexDirection={'column'}
								alignItems={'start'}
								xs={12}
								md={5}
							>
								<Typography
									sx={{
										zIndex: 2,
										position: 'relative',
										fontSize: '24px',
										fontWeight: 600,
										marginLeft: '20px',
										'&::after': {
											display: 'inline-block',
											content: "''",
											left: '-20px',
											right: '0',
											top: '0',
											bottom: '0',
											position: 'absolute',
											backgroundImage: `url(${getLineHighlight().long})`,
											backgroundSize: '100% 80%',
											backgroundRepeat: 'no-repeat',
											backgroundPosition: 'center',
											width: '110%',
											zIndex: -1,
										},
									}}
								>
									{c.district_name}
								</Typography>
								<Wysiwyg
									text={c.distict_contact}
									fontWeight='400'
									variantSize={'subtitle2'}
									sx={{
										color: palette.primary.main,
										fontSize: '24px',
										'& p': {
											marginTop: '16px',
											display: 'block',
										},
									}}
								/>
							</Grid>
						);
					})}
			</Grid>
		</Grid>
	);
};

export default ExtendedContactInfo;
