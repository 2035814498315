import { Autocomplete, Grid, InputLabel, TextField, useTheme } from '@mui/material';

type Props = {
	id: string;
	label: string;
	validated: boolean;
	errorMsg: string;
	value: string;
	values: any;
	valueChangeHandler: (e: any, value: string) => void;
	labelSx?: any;
	multiple?: boolean;
	multipleMax?: number;
};

const FormComboBox = ({
	id,
	label,
	validated,
	errorMsg,
	valueChangeHandler,
	value,
	values,
	labelSx,
	multiple,
	multipleMax,
}: Props) => {
	const { typography, palette } = useTheme();

	return (
		<Grid
			container
			flexDirection={'column'}
			width={'100%'}
			gap={'4px'}
		>
			<InputLabel
				htmlFor={id}
				sx={{
					fontSize: typography.subtitle2.fontSize,
					fontWeight: '500',
					color: palette.primary.main,
					...labelSx,
				}}
			>
				{label}
			</InputLabel>
			<Autocomplete
				fullWidth
				disablePortal
				id={id}
				options={values}
				disableClearable={true}
				multiple={multiple}
				getOptionDisabled={(options: any) => (multiple && multipleMax && value.length >= multipleMax ? true : false)}
				noOptionsText={'Nie znaleziono'}
				size='small'
				renderInput={(params) => (
					<TextField
						{...params}
						label=''
					/>
				)}
				onChange={(e: any, value: any) => {
					multiple ? valueChangeHandler(e, value) : valueChangeHandler(e, e.target.innerText);
				}}
				sx={{
					height: '50px',
					'& *': {
						color: palette.primary.main,
					},
					'& div': {
						py: '0',
						my: '0',
						top: '0',
					},
					'& div:first-of-type': {
						fontSize: '20px',
						display: 'flex',
						alignItems: 'center',
						height: '95%',
					},
					'& fieldset': {
						height: '50px',
					},
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderRadius: '4px',
							borderColor: palette.primary.main,
						},
					},
				}}
			/>
		</Grid>
	);
};

export default FormComboBox;
