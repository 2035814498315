class Address {
	firstLine: string;
	floor: string;
	place: string;
	room: string;
	street: string;
	number: string;
	city: string;
	zipCode: string;

	private constructor(
		firstLine: string,
		floor: string,
		place: string,
		room: string,
		street: string,
		number: string,
		city: string,
		zipCode: string,
	) {
		this.firstLine = firstLine;
		this.floor = floor;
		this.place = place;
		this.room = room;
		this.street = street;
		this.number = number;
		this.city = city;
		this.zipCode = zipCode;
	}

	public static fromApiResponse(acf: any) {
		return new Address(
			acf.first_line,
			acf.floor,
			acf.place,
			acf.room,
			acf.street,
			acf.street_number,
			acf.town,
			acf.zip_code,
		);
	}
}

export default Address;
