import District from './District';

class School {
	id: number;
	rspo: number;
	name: string;
	city: string;
	zipCode: string;
	street: string;
	building: string;
	apartment: string;
	district: District;

	constructor(
		id: number,
		rspo: number,
		name: string,
		city: string,
		zipCode: string,
		street: string,
		building: string,
		apartment: string,
		district: District,
	) {
		this.id = id;
		this.rspo = rspo;
		this.name = name;
		this.city = city;
		this.zipCode = zipCode;
		this.street = street;
		this.building = building;
		this.apartment = apartment;
		this.district = district;
	}

	public static fromApiResponse(data: any) {
		return new School(
			data.id ? data.id : 0,
			data.rspo ? data.rspo : -1,
			data.name ? data.name : '',
			data.city ? data.city : '',
			data.zip_code ? data.zip_code : '',
			data.street ? data.street : '',
			data.building ? data.building : '',
			data.apartment ? data.apartment : '',
			District.fromApiResponse(data.district ? data.district : {}),
		);
	}

	public static createEmpty = () => {
		return new School(-1, 0, '', '', '', '', '', '', { id: -1, name: '' });
	};
}

export default School;
