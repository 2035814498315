import { CircularProgress, Grid } from '@mui/material';

const Loader = () => {
	return (
		<Grid
			item
			container
			flexDirection={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			sx={{
				width: '100%',
				height: '50vh',
			}}
		>
			<CircularProgress
				size={'100px'}
				color={'secondary'}
			/>
		</Grid>
	);
};

export default Loader;
