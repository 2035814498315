const ArrowLeft = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='136'
			height='80'
			viewBox='0 0 136 80'
			fill='none'
			style={{ rotate: '180deg' }}
		>
			<g id='Arrow_2'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M93.3373 42.4268L40 42.4268L40 39.3114L93.3373 39.3114V42.4268Z'
					fill='#fff'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M96.003 40.7941L79.8683 24.7732L77.6498 26.9761L91.566 40.7941L77.6498 54.612L79.8683 56.8149L96.003 40.7941Z'
					fill='#fff'
				/>
			</g>
		</svg>
	);
};

export default ArrowLeft;
