import MainLayout from '../layout/MainLayout';
import { Grid, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
	const { palette } = useTheme();

	return (
		<MainLayout>
			<Grid
				item
				container
				flexDirection={'column'}
				justifyContent={'center'}
				alignItems={'center'}
				sx={{
					backgroundColor: palette.primary.main,
					color: palette.background.default,
					height: '500px',
				}}
			>
				<Typography variant={'h1'}>NIE ZNALEZIONO STRONY</Typography>
				<Link
					to={'/'}
					style={{ textDecorationColor: palette.secondary.light }}
				>
					<Typography
						variant={'h4'}
						sx={{
							color: palette.secondary.light,
						}}
					>
						Wróć do strony głównej
					</Typography>
				</Link>
			</Grid>
		</MainLayout>
	);
};

export default ErrorPage;
