import React, { useContext, useEffect } from 'react';
import { getAllPages, getAllPosts, getMenu, getSettings } from './api/content-api';
import ContentContext from './store/content-context';
import { Route, Routes } from 'react-router-dom';
import Page from './model/Page';
import ScrollToTop from './pages/ScrollToTop';
import ErrorPage from './pages/ErrorPage';
import RegisterForm from './components/Forms/Register/RegisterForm';
import LoginForm from './components/Forms/LoginForm';
import ResetPasswordForm from './components/Forms/ResetPasswordForm';
import { accountPage, loginPage, panelPage, passwordResetPage, registrationPage } from './store/fixed-routing';
import PanelPage from './pages/PanelPage';
import AccountPage from './pages/AccountPage';
import { Helmet } from 'react-helmet';

function App() {
	const { pages, setPages, setMenu, setSettings, registrationEnabled } = useContext(ContentContext);

	useEffect(() => {
		const fetchData = async () => {
			const pages = await getAllPages();
			const posts = await getAllPosts();
			const settings = await getSettings();
			const menu = await getMenu();
			setMenu(menu);
			setPages([...pages, ...posts]);
			setSettings(settings);
		};
		fetchData().catch((error) => console.log(error));
	}, []);

	return (
		<ScrollToTop>
			<Helmet>
				<title>{process.env.REACT_APP_NAME}</title>
				{process.env.REACT_APP_ENV !== 'production' && (
					<meta
						name='robots'
						content='noindex,nofollow'
					/>
				)}
				<link
					rel='icon'
					href={
						process.env.PUBLIC_URL + (process.env.REACT_APP_NAME === 'OLiJP' ? '/favicon.ico' : '/favicon-olijpsp.ico')
					}
				/>
				<link
					rel='manifest'
					href={
						process.env.PUBLIC_URL +
						(process.env.REACT_APP_NAME === 'OLiJP' ? '/manifest.json' : '/manifest-olijpsp.json')
					}
				/>
			</Helmet>
			<Routes>
				{pages.map((pageData: Page) => pageData.toRoute())}
				<Route
					key={'default'}
					path={'*'}
					element={pages.length > 0 ? <ErrorPage /> : <></>}
				/>
				{registrationEnabled && (
					<>
						<Route
							path={registrationPage}
							element={pages.length > 0 ? <RegisterForm /> : <></>}
						/>
						<Route
							path={loginPage}
							element={pages.length > 0 && <LoginForm />}
						/>
						<Route
							path={passwordResetPage}
							element={pages.length > 0 && <ResetPasswordForm />}
						/>
						<Route
							path={accountPage}
							element={pages.length > 0 && <AccountPage />}
						/>
						<Route
							path={panelPage + '/*'}
							element={<PanelPage />}
						/>
					</>
				)}
			</Routes>
		</ScrollToTop>
	);
}

export default App;
