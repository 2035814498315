import { red } from '@mui/material/colors';

export const olijpTheme = {
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
		},
	},
	palette: {
		background: {
			default: '#f6f6f4',
		},
		primary: {
			main: '#3d506c',
			light: '#f6f6f4',
		},
		secondary: {
			// main: '#bc3766',
			main: '#3C616B',
			light: '#ffca00',
		},
		customColors: {
			main: '#F5F5F5',
			gray: '#C2C2C2',
		},
		error: {
			main: red.A400,
		},
	},
	typography: {
		fontSize: 16,
		fontFamily: ['Jost', 'Jost-italic'].join(','),
		body1: {
			fontSize: '18px',
			fontWeight: 400,
			'@media (max-width: 900px)': {
				fontSize: '22px',
			},
		},
		body2: {
			fontSize: '14px',
			fontWeight: 400,
		},
		h1: {
			fontSize: '39px',
			fontWeight: 500,
			'@media (max-width: 1200px)': {
				fontSize: '32px',
			},
			'@media (max-width: 900px)': {
				fontSize: '30px',
			},
		},
		h2: {
			fontSize: '36px',
			textAlign: 'left',
			fontWeight: 400,
		},
		h3: {
			fontSize: '32px',
			fontWeight: 400,
			'@media (max-width: 600px)': {
				fontSize: '24px',
			},
		},
		h4: {
			fontSize: '22px',
			textAlign: 'left',
			fontWeight: 400,
			'@media (max-width: 900px)': {
				fontSize: '20px',
			},
		},
		h5: {
			fontSize: '18px',
			fontWeight: 300,
		},
		h6: {
			fontSize: '14px',
			fontWeight: 300,
			textAlign: 'left',
		},
		subtitle1: {
			fontSize: '18px',
			fontWeight: '500',
			'@media (max-width: 1200px)': {
				fontSize: '26px',
			},
			'@media (max-width: 900px)': {
				fontSize: '22px',
			},
			'@media (max-width: 600px)': {
				fontSize: '18px',
			},
		},
		subtitle2: {
			fontSize: '16px',
			fontWeight: '400',
		},
		title: {
			fontSize: '32px',
			fontWeight: '500',
		},
	},
};
