import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	useTheme,
} from '@mui/material';
import { sendTeacherApproval } from '../../api/content-api';
import Teacher from '../../model/Teacher';
import { loginPage } from '../../store/fixed-routing';
import { useContext, useState } from 'react';
import AuthContext from '../../store/auth-context';
import SnackbarContext from '../../store/snackbar-context';
import { useNavigate } from 'react-router-dom';

type Props = {
	teacher: Teacher | null | undefined;
	close: () => void;
	onSuccess: () => void;
};
const ApproveTeacherDialog = ({ teacher, close, onSuccess }: Props) => {
	const { palette } = useTheme();
	const { token, logout } = useContext(AuthContext);
	const navigate = useNavigate();
	const { setMsg } = useContext(SnackbarContext);
	const [sendingRequest, setSendingRequest] = useState(false);

	const handleApprove = (id: number | undefined) => {
		setSendingRequest(true);
		if (sendingRequest) {
			return;
		}
		sendTeacherApproval(token, id)
			.then((response) => {
				if (response.status === 200) {
					onSuccess();
					setMsg({ msg: 'Dane zostały zaktualizowane', severity: 'success' });
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
				setSendingRequest(false);
			})
			.catch((exception) => {
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
				} else {
					setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
				}
				setSendingRequest(false);
			});
	};

	return (
		<>
			<Dialog
				open={!!teacher}
				onClose={() => close()}
			>
				<DialogTitle sx={{ fontSize: '24px' }}>Czy napewno chcesz potwierdzić nauczyciela?</DialogTitle>
				<DialogContent>
					<Typography sx={{ fontSize: '16px' }}>{teacher?.academicDegree}</Typography>
					<Typography sx={{ fontSize: '16px' }}>
						<Box
							component={'span'}
							sx={{
								fontWeight: 600,
							}}
						>
							Imię i nazwisko:
						</Box>{' '}
						{teacher?.firstName} {teacher?.lastName}
					</Typography>
					<Typography sx={{ fontSize: '16px' }}>
						<Box
							component={'span'}
							sx={{
								fontWeight: 600,
							}}
						>
							Afiliacja:
						</Box>{' '}
						{teacher?.affiliation}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						sx={{
							color: palette.secondary.main,
						}}
						onClick={() => close()}
					>
						Anuluj
					</Button>
					<Button
						sx={{
							color: palette.success.main,
						}}
						onClick={() => handleApprove(teacher?.id)}
					>
						{sendingRequest ? <CircularProgress /> : 'Zapisz'}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ApproveTeacherDialog;
