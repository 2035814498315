import PanelLayout from '../layout/PanelLayout';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../store/auth-context';
import { useLocation, useNavigate } from 'react-router-dom';
import PanelMenu from '../components/AdminPanel/PanelMenu';
import DistrictsTable from '../components/AdminPanel/DistrictsTable';
import { Box, Button, Grid, ThemeProvider } from '@mui/material';
import SchoolsTable from '../components/AdminPanel/SchoolsTable';
import TeachersTable from '../components/AdminPanel/TeachersTable';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
	districtsPage,
	olympicsSettingsPage,
	olympicsStagesPage,
	schoolsPage,
	studentsPage,
	teachersPage,
} from '../store/fixed-routing';
import StudentsTable from '../components/AdminPanel/StudentsTable';
import SettingsPage from '../components/AdminPanel/SettingsPage';
import StagesTable from '../components/AdminPanel/StagesTable';
import theme from '../styles/theme';
import { OlympicsContextProvider } from '../store/olympics-context';
import PanelHeader from '../components/AdminPanel/PanelHeader';

const PanelPage = () => {
	const { role, logout } = useContext(AuthContext);
	const [index, setIndex] = useState(1);
	const navigate = useNavigate();
	const location = useLocation();
	const adminTheme = {
		...theme,
		typography: {
			...theme.typography,
			body1: {
				fontSize: '18px',
				fontWeight: 400,
				'@media (max-width: 900px)': {
					fontSize: '22px',
				},
			},
		},
	};

	useEffect(() => {
		switch (location.pathname) {
			case districtsPage:
				setIndex(1);
				break;
			case schoolsPage:
				setIndex(2);
				break;
			case teachersPage:
				setIndex(3);
				break;
			case studentsPage:
				setIndex(4);
				break;
			case olympicsStagesPage:
				setIndex(5);
				break;
			case olympicsSettingsPage:
				setIndex(6);
				break;
			default:
				setIndex(1);
		}
	}, [location]);

	useEffect(() => {
		if (!(role === 'administrator' || role === 'teacher' || role === 'secretary')) {
			navigate('/');
		}
	}, [role]);

	return (
		<ThemeProvider theme={adminTheme}>
			<OlympicsContextProvider>
				<PanelLayout>
					<PanelMenu setIndex={setIndex} />
					<Box
						sx={{
							width: { xs: '100%', md: 'calc(100vw - 200px)' },
							height: '100%',
							ml: 'auto',
						}}
					>
						<Grid
							item
							container
							flexDirection={'column'}
							sx={{
								p: '30px',
							}}
						>
							<PanelHeader index={index} />
							{index === 1 && <DistrictsTable />}
							{index === 2 && <SchoolsTable />}
							{index === 3 && <TeachersTable />}
							{index === 4 && <StudentsTable />}
							{index === 5 && <StagesTable />}
							{index === 6 && <SettingsPage />}
						</Grid>
					</Box>
					<Button
						size={'small'}
						startIcon={<LockOpenIcon />}
						onClick={() => logout()}
						sx={{
							position: 'fixed',
							top: '10px',
							right: '10px',
							fontSize: '14px',
						}}
					>
						Wyloguj
					</Button>
				</PanelLayout>
			</OlympicsContextProvider>
		</ThemeProvider>
	);
};

export default PanelPage;
