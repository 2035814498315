import { CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import EditOffIcon from '@mui/icons-material/EditOff';
import Student from '../../../model/Student';

type Props = {
	student: Student;
	editedStudent?: Student | null;
	inProgress: boolean;
	disabled?: boolean;
	handleSave: () => void;
	handleClose: () => void;
	handleEdit: () => void;
};

const ActionButtons = ({
	student,
	editedStudent,
	disabled,
	inProgress,
	handleSave,
	handleClose,
	handleEdit,
}: Props) => {
	return disabled ? (
		<Tooltip title={'Nie można już wprowadzać zmian'}>
			<EditOffIcon
				color={'disabled'}
				fontSize={'small'}
			/>
		</Tooltip>
	) : editedStudent?.id === student.id ? (
		<Grid
			container
			flexWrap={'nowrap'}
			justifyContent={'center'}
			alignItems={'center'}
		>
			{inProgress ? (
				<CircularProgress size={30} />
			) : (
				<>
					<Tooltip title={'Zapisz'}>
						<IconButton
							color={'success'}
							onClick={handleSave}
							sx={{
								padding: 0,
								width: '50%',
							}}
						>
							<SaveIcon fontSize={'small'} />
						</IconButton>
					</Tooltip>
					<Tooltip title={'Anuluj'}>
						<IconButton
							color={'warning'}
							onClick={handleClose}
							sx={{
								padding: 0,
								width: '50%',
							}}
						>
							<CancelIcon fontSize={'small'} />
						</IconButton>
					</Tooltip>
				</>
			)}
		</Grid>
	) : (
		<Tooltip title={'Edytuj'}>
			<IconButton
				onClick={handleEdit}
				sx={{
					padding: 0,
					width: '100%',
				}}
			>
				<ModeEditIcon fontSize={'small'} />
			</IconButton>
		</Tooltip>
	);
};

export default ActionButtons;
