import { AppBar, Box, Drawer, Grid, IconButton, Link, Toolbar, Typography, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import MenuIcon from '../../ui/icons/MenuIcon';
import CloseIcon from '../../ui/icons/CloseIcon';
import { MenuItem } from '../../model/Menu';
import MainButton from '../UI/MainButton';
import { loginPage, registrationPage } from '../../store/fixed-routing';
import ContentContext from '../../store/content-context';
import { getLineHighlight } from '../../styles/theme';
import { uuidv4 } from '../../utils/string-tools';

type MenuItemLinkProps = {
	menuItem: MenuItem;
	closeDrawer: () => void;
	mainMenu?: boolean;
};

const MenuItemLink = ({ menuItem, closeDrawer, mainMenu }: MenuItemLinkProps) => {
	const { palette } = useTheme();

	const fontSize = (menuItem?.subMenu && menuItem.subMenu.length > 0) || mainMenu ? '24px' : '16px !important';

	return (
		<Link
			href={menuItem.url}
			onClick={closeDrawer}
		>
			<Typography
				sx={{
					textDecoration: 'none',
					fontWeight: 400,
					color: palette.primary.light,
					fontSize: fontSize,
					letterSpacing: '0.18px',
					cursor: 'pointer',
					borderBottom: mainMenu ? `1px solid ${palette.secondary.light}` : `1px solid transparent`,
					'&:active': {
						borderBottom: `1px solid ${palette.secondary.light}`,
					},
				}}
			>
				{menuItem.title}
			</Typography>
		</Link>
	);
};

const MenuBuilder = ({ menuItem, closeDrawer, mainMenu }: MenuItemLinkProps) => {
	return (
		<>
			<MenuItemLink
				menuItem={menuItem}
				closeDrawer={closeDrawer}
				mainMenu={mainMenu}
			/>
			{menuItem?.subMenu &&
				menuItem.subMenu.length > 0 &&
				menuItem?.subMenu?.map((item: any, index: number) => {
					return (
						<MenuBuilder
							key={'submenu-' + uuidv4()}
							menuItem={item}
							closeDrawer={closeDrawer}
						/>
					);
				})}
		</>
	);
};

const MinHeader = ({ menu }: any) => {
	const { palette } = useTheme();
	const [openDrawer, setOpenDrawer] = useState(false);
	const { registrationEnabled } = useContext(ContentContext);

	return (
		<Grid
			component={'header'}
			container
			sx={{
				position: 'relative',
				backgroundColor: palette.primary.light,
				zIndex: 100,
			}}
		>
			<AppBar
				position='relative'
				sx={{
					height: { xs: '68px', sm: '75px', zIndex: 100 },
					boxShadow: 'none',
					backgroundColor: palette.primary.light,
					maxWidth: '100%',
					zIndex: 100,
				}}
				component={'header'}
			>
				<Toolbar
					variant='dense'
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						padding: '14px 5%',
						minHeight: '0px',
						maxWidth: '100%',
					}}
				>
					<Link
						sx={{ display: 'flex', alignItems: 'center' }}
						href={'/'}
					>
						<Box
							component={'img'}
							src={menu?.logo?.url}
							sx={{
								maxWidth: { xs: '144px', lg: '184px' },
								objectFit: 'cover',
							}}
						/>
					</Link>
					<IconButton onClick={() => setOpenDrawer(true)}>
						<MenuIcon color={palette.primary.main} />
					</IconButton>
				</Toolbar>
			</AppBar>

			<Drawer
				anchor={'right'}
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
				PaperProps={{
					sx: {
						backgroundColor: palette.primary.main,
						color: palette.primary.light,
					},
				}}
				sx={{
					zIndex: 100000,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '30px',
						width: { xs: '100dvw', sm: '60dvw' },
						marginBottom: '64px',
					}}
				>
					<IconButton
						onClick={() => setOpenDrawer(false)}
						sx={{ margin: '64px 0' }}
					>
						<CloseIcon color={palette.primary.light} />
					</IconButton>
					{menu?.menuItems.map((item: any, index: number) => (
						<MenuBuilder
							key={'menu-main-' + index}
							menuItem={item}
							mainMenu={true}
							closeDrawer={() => setOpenDrawer(false)}
						/>
					))}
					{registrationEnabled && (
						<Grid
							item
							pt='20px'
						>
							<MainButton
								darkVersion
								noPinkHighlight
								title={menu?.login}
								href={loginPage}
								sx={{
									p: '8px 24px',
									color: palette.primary.light,
									borderColor: palette.primary.light,
									fontSize: { xs: '20px', md: '22px' },
								}}
							/>
						</Grid>
					)}
					{/*<Grid item>*/}
					{/*	<Link*/}
					{/*		href={menu?.option_link?.url}*/}
					{/*		sx={{ textDecoration: 'none' }}*/}
					{/*	>*/}
					{/*		<Box*/}
					{/*			display='flex'*/}
					{/*			gap='8px'*/}
					{/*		>*/}
					{/*			<AccessibilityIcon color={palette.primary.light} />*/}
					{/*			<StyledTypography sx={{ fontSize: { xs: '20px', md: '22px' } }}>{menu?.option_title}</StyledTypography>*/}
					{/*		</Box>*/}
					{/*	</Link>*/}
					{/*</Grid>*/}
					{/*<Grid item>*/}
					{/*	<StyledTypography sx={{ fontSize: { xs: '20px', md: '22px' } }}>{menu?.language} | PL</StyledTypography>*/}
					{/*</Grid>*/}
					{registrationEnabled && (
						<Grid
							item
							pb={3}
						>
							<Link href={registrationPage}>
								<Box
									sx={{
										display: 'inline-block',
										backgroundImage: `url(${getLineHighlight().long})`,
										backgroundRepeat: 'round',
										backgroundPosition: '0 0',
										height: '90%',
										width: '115%',
										color: palette.primary.main,
										fontSize: { xs: '24px', md: '26px' },
										letterSpacing: '0.18px',
										fontWeight: '500',
										textDecoration: 'underline',
										padding: '2px 0px 4px 20px',
									}}
								>
									{menu?.signIn}
								</Box>
							</Link>
						</Grid>
					)}
				</Box>
			</Drawer>
		</Grid>
	);
};

export default MinHeader;
