import { useTheme } from '@mui/material';
import { useState } from 'react';
import BlockWrapper from '../../components/BlockWrapper';
import RightPane from './RightPane';
import LeftPane from './LeftPane';

type Props = {
	content: any;
};

const ScheduleWithSwitchContent = ({ content }: Props) => {
	const { breakpoints } = useTheme();
	const [currentPage, setCurrentPage] = useState(0);

	return (
		<BlockWrapper
			justify={'center'}
			lgSize={12}
			xlSize={12}
			withPadding={false}
			sx={{
				scrollBehavior: 'smooth',
				position: 'relative',
				[breakpoints.up('lg')]: {
					justifyContent: 'flex-end',
				},
				[breakpoints.down('lg')]: {
					flexDirection: 'column-reverse',
					ml: '0',
				},
			}}
		>
			<LeftPane
				content={content.switch_content[currentPage].schedule_step}
				links={content.switch_content[currentPage].schedule_item_links}
				centered={content.switch_content[currentPage].centered}
			/>
			<RightPane
				content={content.switch_content[currentPage]}
				previous={currentPage > 0 ? content.switch_content[currentPage - 1].main_title : ''}
				next={currentPage < content.switch_content.length - 1 ? content.switch_content[currentPage + 1].main_title : ''}
				onStepChange={(inc: number) => {
					setCurrentPage((prevState) => prevState + inc);
				}}
			/>
		</BlockWrapper>
	);
};

export default ScheduleWithSwitchContent;
