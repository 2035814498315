import { createContext, ReactNode, useReducer } from 'react';
import isEmail from 'validator/lib/isEmail';

type MangedFormContextType = {
	initialData: any;
	data: any;
	getFormData: () => any;
	setData: (data: any) => void;
	setInitialProperties: (
		accessor: string,
		initialValue: any,
		errorMessage: string,
		optional: boolean,
		error?: boolean,
	) => void;
	validated: boolean;
	validate: () => string | null;
	setValue: (accessor: string, val: any) => void;
	setTextValue: (accessor: string, val: string, max?: number) => void;
	setNumberValue: (accessor: string, val: string, min?: number, max?: number) => void;
	setEmailValue: (accessor: string, val: string) => void;
	setZipCodeValue: (accessor: string, val: string) => void;
	reset: () => void;
};

type ActionType = {
	type: string;
	value?: any;
};

const SET_DATA = 'setData';
const SET_INITIAL_PROPERTIES = 'setInitialProperties';
const SET_STATE = 'resetState';
const SET_VALIDATED = 'setValidated';

const ZIP_CODE_PL_REGEX = '^[0-9]{2}-[0-9]{3}$';

const INITIAL_MANAGED_FORM_STATE: MangedFormContextType = {
	initialData: {},
	data: {},
	getFormData: () => {},
	validated: false,
	validate: () => null,
	setData: (data: any) => {},
	setInitialProperties: (
		accessor: string,
		initialValue: any,
		errorMessage: string,
		optional: boolean,
		error?: boolean,
	) => {},
	setValue: (accessor: string, val: any) => {},
	setTextValue: (accessor: string, val: string) => {},
	setNumberValue: (accessor: string, val: string, min = undefined, max = undefined) => {},
	setEmailValue: (accessor: string, val: string) => {},
	setZipCodeValue: (accessor: string, val: string) => {},
	reset: () => {},
};

const setInitialProperty = (
	obj: any,
	initialValue: any,
	accessor: string,
	errorMessage: string,
	optional: boolean,
	error?: boolean,
): any => {
	const [head, ...rest] = accessor.split('.');

	if (rest.length > 0) {
		return {
			...obj,
			[head]: setInitialProperty(obj[head], initialValue, rest.join('.'), errorMessage, optional, error),
		};
	} else {
		return {
			...obj,
			[head]: {
				value: initialValue ? initialValue : obj[head],
				error: error,
				optional: optional,
				errorMessage,
			},
		};
	}
};

const contentReducer = (state: MangedFormContextType, action: ActionType): MangedFormContextType => {
	switch (action.type) {
		case SET_STATE: {
			return action.value;
		}
		case SET_DATA: {
			return {
				...state,
				data: action.value,
			};
		}
		case SET_INITIAL_PROPERTIES: {
			return {
				...state,
				data: setInitialProperty(
					state.data,
					action.value.initialValue,
					action.value.accessor,
					action.value.errorMessage,
					action.value.optional,
					action.value.error,
				),
			};
		}
		case SET_VALIDATED: {
			return {
				...state,
				validated: true,
			};
		}
		default:
			return state;
	}
};

type ManagedFormProps = {
	data?: any;
	children: ReactNode;
};

export const MangedForm = ({ children }: ManagedFormProps) => {
	const [state, dispatch] = useReducer(contentReducer, INITIAL_MANAGED_FORM_STATE);

	const setProperty = (obj: any, accessor: string, value: any, error: boolean): any => {
		const [head, ...rest] = accessor.split('.');

		if (rest.length) {
			return {
				...obj,
				[head]: setProperty(obj[head], rest.join('.'), value, error),
			};
		} else {
			return {
				...obj,
				[head]: {
					...obj[head],
					value,
					error,
				},
			};
		}
	};

	const flattenData = (obj: any, parentKey: string | null = null): any => {
		const result = {};

		for (const key in obj) {
			const value = obj[key];

			if (value && typeof value === 'object' && !Array.isArray(value)) {
				const nestedObject = flattenData(value, key);
				for (const nestedKey in nestedObject) {
					// @ts-ignore
					result[nestedKey] = nestedObject[nestedKey];
				}
			} else if (key === 'value') {
				// @ts-ignore
				if (parentKey) {
					// @ts-ignore
					result[parentKey] = value;
				} else {
					// @ts-ignore
					result[key] = value;
				}
			}
		}

		return result;
	};

	const setTextValue = (accessor: string, val: any, max?: number): void => {
		const value = val ? val.toString() : '';
		let updated: any;
		if (max && value.length > max) {
			return;
		}
		if (value.length >= 1) {
			updated = setProperty(state.data, accessor, value, false);
		} else {
			updated = setProperty(state.data, accessor, value, true);
		}
		dispatch({ type: SET_DATA, value: updated });
	};

	const setEmailValue = (accessor: string, val: any): void => {
		const value = val ? val.toString() : '';
		let updated: any;
		if (isEmail(value)) {
			updated = setProperty(state.data, accessor, value, false);
		} else {
			updated = setProperty(state.data, accessor, value, true);
		}
		dispatch({ type: SET_DATA, value: updated });
	};

	const setNumberValue = (accessor: string, value: string, min?: number, max?: number): void => {
		const val = Number(value);
		if (isNaN(val)) {
			return;
		}
		let updated: any;
		if (min && val < min) {
			updated = setProperty(state.data, accessor, val, true);
		} else if (max && val > max) {
			updated = setProperty(state.data, accessor, val, true);
		} else {
			updated = setProperty(state.data, accessor, val, false);
		}
		dispatch({ type: SET_DATA, value: updated });
	};

	const setZipCodeValue = (accessor: string, value: string): void => {
		const inputRegex = new RegExp('^[0-9-]*$');
		if (!inputRegex.test(value)) {
			return;
		}
		const reg = new RegExp(ZIP_CODE_PL_REGEX);
		let updated: any;
		if (reg.test(value)) {
			updated = setProperty(state.data, accessor, value, false);
		} else {
			updated = setProperty(state.data, accessor, value, true);
		}
		dispatch({ type: SET_DATA, value: updated });
	};

	const setValue = (accessor: string, value: any): void => {
		let updated: any;
		if (value) {
			updated = setProperty(state.data, accessor, value, false);
		} else {
			updated = setProperty(state.data, accessor, value, true);
		}
		dispatch({ type: SET_DATA, value: updated });
	};

	const validateFormObject = (data: any): string | null => {
		let result = null;
		for (const key in data) {
			if (key === 'error' && data['error'] && !data['optional']) {
				result = data['errorMessage'];
			} else if (typeof data[key] === 'object') {
				result = validateFormObject(data[key]);
			}
			if (result) break;
		}
		return result;
	};
	const validate = (): string | null => {
		dispatch({ type: SET_VALIDATED });
		return validateFormObject(state.data);
	};

	const reset = (): void => {
		dispatch({ type: SET_STATE, value: INITIAL_MANAGED_FORM_STATE });
	};

	const setInitialProperties = (
		accessor: string,
		initialValue: any,
		errorMessage: string,
		optional: boolean,
		error?: boolean,
	) => {
		dispatch({ type: SET_INITIAL_PROPERTIES, value: { accessor, initialValue, errorMessage, optional, error } });
	};

	const getFormData = () => {
		return flattenData(state.data);
	};

	const contextValue: MangedFormContextType = {
		...state,
		getFormData,
		setInitialProperties,
		setValue,
		setTextValue,
		setNumberValue,
		setEmailValue,
		setZipCodeValue,
		validate,
		reset,
	};
	return <ManagedFormContext.Provider value={contextValue}>{children}</ManagedFormContext.Provider>;
};

const ManagedFormContext = createContext<MangedFormContextType>({
	...INITIAL_MANAGED_FORM_STATE,
});

export default ManagedFormContext;
