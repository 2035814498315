import React from 'react';
import { Button, styled, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)({
	textDecoration: 'none',
});

type Props = {
	link?: any;
	href?: string;
	onClick?: () => void;
	target?: string;
	darkVersion?: boolean;
	noPinkHighlight?: boolean;
	disabled?: boolean;
	title?: string;
	sx?: any;
};

const MainButton = ({ link, href, target, onClick, darkVersion, noPinkHighlight, title, sx, disabled }: Props) => {
	const {
		palette: { primary, secondary },
		typography,
	} = useTheme();

	return (
		<StyledLink
			to={link?.url ? link.url : href}
			target={link?.target ? link.target : target}
		>
			<Button
				onClick={() => (link || href || disabled ? {} : onClick && onClick())}
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '8px',
					p: '16px 24px',
					borderRadius: '40px',
					border: '1px solid',
					borderColor: disabled ? 'lightGrey' : darkVersion ? primary.light : primary.main,
					fontSize: typography.h5.fontSize,
					fontFamily: typography.body1.fontFamily,
					fontWeight: '400',
					textTransform: 'none',
					textDecoration: 'none',
					textDecorationLine: 'none',
					whiteSpace: { xs: 'normal', md: 'nowrap' },
					transition: 'all .15s ease-in-out',
					backgroundColor: darkVersion ? primary.main : primary.light,
					color: `${disabled ? 'lightGrey' : darkVersion ? primary.light : primary.main}`,
					'&:hover': {
						borderColor: disabled ? 'lightGrey' : secondary.main,
						backgroundColor: darkVersion && (noPinkHighlight ? primary.main : secondary.main),
					},
					...sx,
				}}
			>
				{link ? link.title : title}
			</Button>
		</StyledLink>
	);
};

export default MainButton;
