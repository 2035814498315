import { useState } from 'react';
import { Box, useTheme, Collapse } from '@mui/material';
import Wysiwyg from '../components/UI/Wysiwyg';
import BlockWrapper from '../components/BlockWrapper';
import AddIcon from '../ui/icons/AddIcon';
import MinusIcon from '../ui/icons/MinusIcon';

type Props = {
	index: number;
	item: any;
};

const DropDown = ({ index, item }: Props) => {
	const { palette } = useTheme();
	const [dropDownState, setDropDownState] = useState<boolean[]>([]);

	const handleClick = (index: number): (() => void) => {
		return () => {
			setDropDownState((prevState: boolean[]) => {
				const copy: boolean[] = [...prevState];
				copy[index] = !dropDownState[index];
				return copy;
			});
		};
	};

	return (
		<Box
			key={index}
			mb='40px'
			onClick={handleClick(index)}
			sx={{ cursor: 'pointer' }}
		>
			<Box
				display='flex'
				justifyContent='space-between'
				alignItems='center'
				pb='8px'
				sx={{ borderBottom: `1px solid ${palette.primary.main}` }}
			>
				<Wysiwyg
					text={item.dropdown_title}
					fontWeight={'500'}
					variantSize={'h5'}
					sx={{
						width: { xs: '90%', md: 'auto' },
						letterSpacing: '0.18px',
					}}
				/>
				{dropDownState[index] ? <MinusIcon /> : <AddIcon />}
			</Box>
			<Collapse
				in={dropDownState[index]}
				sx={{ pt: '8px' }}
			>
				<Wysiwyg
					text={item.dropdown_desc}
					fontWeight={'400'}
					variantSize={'h5'}
					sx={{
						'& a': {
							color: palette.primary.main,
							cursor: 'pointer',
						},
						'& p': {
							padding: '20px 0 20px',
						},
						'& ul': {
							marginLeft: '-5px',
						},
						letterSpacing: '0.18px',
						cursor: 'default',
					}}
				/>
			</Collapse>
		</Box>
	);
};

const DropDownListSection = ({ content }: any) => {
	return (
		<BlockWrapper
			smSize={10}
			lgSize={6}
			xlSize={6}
			justify={'center'}
			withPadding={false}
			sx={{
				padding: { xs: '0 26px', md: '' },
				flexDirection: 'column',
				alignItems: 'flex-start',
			}}
		>
			{content.dropdown_header &&
				content.dropdown_header.map((elem: any, index: number) => (
					<Box
						key={index}
						display='flex'
						flexDirection='column'
						paddingBottom='20px'
					>
						<Wysiwyg
							text={elem.list_title && elem.list_title}
							fontWeight={'500'}
							variantSize={'h4'}
							sx={{
								pb: '24px',
								letterSpacing: '0.22px',
							}}
						/>
						{elem.dropdown_list.map((item: any, index: number) => (
							<Box key={index}>
								<DropDown
									index={index}
									item={item}
								/>
							</Box>
						))}
					</Box>
				))}
		</BlockWrapper>
	);
};

export default DropDownListSection;
