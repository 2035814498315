import { Box, Grid, useTheme } from '@mui/material';
import MainButton from '../components/UI/MainButton';
import Wysiwyg from '../components/UI/Wysiwyg';

type Props = {
	content: any;
};

const ImageAndTextWithButtons = ({ content }: Props) => {
	const { palette, breakpoints, typography } = useTheme();

	return (
		<Grid
			component={'section'}
			display={'flex'}
			flexDirection={content.which_side === 'left' ? 'row' : 'row-reverse'}
			gap={'20px'}
			bgcolor={palette.primary.main}
			sx={{
				[breakpoints.down('lg')]: {
					flexDirection: 'column',
				},
			}}
		>
			{content.image && (
				<Box
					component={'img'}
					src={content.image.url}
					alt={content.image.alt}
					sx={{
						minWidth: '50%',
						minHeight: '55vh',
						objectFit: 'cover',
						[breakpoints.down('xl')]: {
							minWidth: '45%',
						},
						[breakpoints.down('lg')]: {
							width: 'auto',
							height: 'auto',
							maxHeight: '50vh',
						},
						[breakpoints.down('md')]: {
							minHeight: 'auto',
						},
					}}
				/>
			)}
			<Box
				component={'section'}
				width={'50%'}
				display={'flex'}
				flexDirection={'column'}
				justifyContent={'center'}
				alignItems={'flex-start'}
				gap={'48px'}
				pr={{ xs: '26px', lg: '20px' }}
				my={'80px'}
				sx={{
					color: palette.primary.light,
					'& *': {
						color: palette.primary.light,
					},
					[breakpoints.down('xl')]: {
						width: '55%',
					},
					[breakpoints.down('lg')]: {
						width: '100%',
						pl: '26px',
					},
				}}
			>
				<Box
					display={'flex'}
					flexDirection={'column'}
					alignItems={'flex-start'}
					justifyContent={'center'}
					gap={'24px'}
					ml={content.which_side !== 'left' ? '24px' : '0'}
				>
					{content.first_text && (
						<Wysiwyg
							text={content.first_text}
							fontWeight={'500'}
							variantSize={'h3'}
						/>
					)}
					{content.second_text && (
						<Wysiwyg
							text={content.second_text}
							fontWeight={'400'}
							variantSize={'h5'}
						/>
					)}
				</Box>
				<Box
					component={'nav'}
					display={'flex'}
					justifyContent={'center'}
					alignItems={'center'}
					gap={'40px'}
					width={'100%'}
					flexWrap={'wrap'}
				>
					{content.buttons &&
						content.buttons.map((item: any, index: number) => {
							return (
								<MainButton
									key={'button-' + index}
									link={item.link}
									darkVersion
									noPinkHighlight
								/>
							);
						})}
				</Box>
			</Box>
		</Grid>
	);
};

export default ImageAndTextWithButtons;
