import DOMPurify from 'isomorphic-dompurify';
import Address from '../model/Address';

export const getRelativeLink = (url: string | undefined): string => {
	if (url) {
		const link = new URL(url ? url : '');
		return removeTrailingSlash(link.pathname + link.search);
	} else {
		return '';
	}
};

export const cleanUpHtml = (content: string, config?: DOMPurify.Config): string => {
	return `${DOMPurify.sanitize(content, {
		...{ ADD_ATTR: ['target'] },
		...config,
	})}`;
};

export const removeTrailingSlash = (str: string | undefined) => {
	if (str?.endsWith('/')) {
		return str.slice(0, -1);
	}
	return str ? str : '';
};

export const romanize = (num: number) => {
	var lookup = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 },
		roman = '',
		i;
	for (i in lookup) {
		// @ts-ignore
		while (num >= lookup[i]) {
			roman += i;
			// @ts-ignore
			num -= lookup[i];
		}
	}
	return roman;
};

export const formatAddress = (a: Address) => {
	let address = '';
	if (a.street && a.street != '') {
		address += `${a.street} ${a.number}, `;
	}
	if (a.zipCode && a.zipCode != '' && a.city && a.city != '' && a.place && a.place != '') {
		address += `${a.zipCode} ${a.city} ${a.place}, `;
	}
	if (a.floor && a.floor != '') {
		address += `${a.floor}, `;
	}
	if (a.room && a.room != '') {
		address += `${a.room}`;
	}
	return address;
};

export const uuidv4 = () => {
	return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
		// @ts-ignore
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
	);
};
