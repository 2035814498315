import {
	Button,
	CircularProgress,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
	useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NewSchoolDialog from './NewSchoolDialog';
import React, { useContext, useEffect, useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PersonIcon from '@mui/icons-material/Person';
import { MangedForm } from '../../ui/Form/MangedForm';
import { downloadReport } from '../../api/content-api';
import AuthContext from '../../store/auth-context';
import SnackbarContext from '../../store/snackbar-context';
import { loginPage } from '../../store/fixed-routing';
import { useNavigate } from 'react-router-dom';
import OlympicsContext from '../../store/olympics-context';
import NewStudentDialog from './NewStudentDialog';
import { STAGE_0, STAGES_TITLES } from '../../utils/stages';
import ChangePublishedStageDialog from './ChangePublishedStageDialog';
import { StageOption } from './StagesTable';
import TeacherEditDialog from './TeacherEditDialog';

type Props = {
	index: number;
};
const PanelHeader = ({ index }: Props) => {
	const { palette } = useTheme();
	const [openNewSchoolDialog, setOpenNewSchoolDialog] = useState(false);
	const [openNewStudentDialog, setOpenNewStudentDialog] = useState(false);
	const [openTeacherEditDialog, setOpenTeacherEditDialog] = useState(false);
	const { token, logout, isSecretary, isTeacher, isAdmin, districtId, id } = useContext(AuthContext);
	const { olympics, setDistrictFilterId, districtFilterId, districts } = useContext(OlympicsContext);
	const navigate = useNavigate();
	const { setMsg } = useContext(SnackbarContext);
	const [isSending, setIsSending] = useState(false);
	const [publishedStageForDialog, setPublishedStageForDialog] = useState<StageOption>(STAGE_0);
	const [enabledDistrictSearch, setEnabledDistrictSearch] = useState(false);

	useEffect(() => {
		const enabled = isAdmin || index === 2 || index === 3 || index === 4;
		if (!enabled) {
			setDistrictFilterId(districtId ? districtId : -1);
		}
		setEnabledDistrictSearch(enabled);
	}, [index, districtId]);

	const handlePublishedStageForDialog = (event: SelectChangeEvent<StageOption>) => {
		const value = event.target.value as unknown as StageOption;
		if (olympics && value.key > olympics.getPublishedStage().key) {
			setPublishedStageForDialog(value);
		}
	};

	const getTitle = () => {
		switch (index) {
			case 1:
				return 'Okręgi';
			case 2:
				return 'Szkoły';
			case 3:
				return 'Nauczyciele';
			case 4:
				return 'Uczniowie';
			case 5:
				return 'Etapy';
			case 6:
				return 'Ustawienia';
		}
	};

	const getReportType = () => {
		switch (index) {
			case 2:
				return 'schools';
			case 3:
				return 'teachers';
			case 4:
				return 'students';
			case 5:
				return 'results';
			default:
				return 'districts';
		}
	};

	const downloadExcel = () => {
		const type = getReportType();
		setIsSending(true);
		downloadReport(token, type)
			.then((response) => {
				const now = new Date();
				const year = now.getFullYear().toString().slice(-2);
				const month = String(now.getMonth() + 1).padStart(2, '0');
				const day = String(now.getDate()).padStart(2, '0');
				const hours = String(now.getHours()).padStart(2, '0');
				const minutes = String(now.getMinutes()).padStart(2, '0');
				const seconds = String(now.getSeconds()).padStart(2, '0');
				const timestamp = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
				let fileName: string;
				switch (type) {
					case 'schools': {
						fileName = 'Szkoły-' + timestamp + '.xlsx';
						break;
					}
					case 'teachers': {
						fileName = 'Nauczyciele-' + timestamp + '.xlsx';
						break;
					}
					case 'students': {
						fileName = 'Uczniowie-' + timestamp + '.xlsx';
						break;
					}
					case 'results': {
						fileName = 'Wyniki-' + timestamp + '.xlsx';
						break;
					}
					default: {
						fileName = 'Okręgi-' + timestamp + '.xlsx';
						break;
					}
				}
				const url = window.URL.createObjectURL(response.data);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.setAttribute('download', fileName);
				a.href = url;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
				setIsSending(false);
				setMsg({ msg: 'Pobrano nowy raport', severity: 'info' });
			})
			.catch((exception) => {
				setIsSending(false);
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
				} else {
					const msg = exception?.response?.data?.message
						? exception?.response?.data?.message
						: 'Błąd serwera. Spróbuj za chwilę';
					setMsg({ msg });
				}
			});
	};

	return (
		<>
			<Typography
				variant={'h5'}
				sx={{
					color: palette.primary.main,
				}}
			>
				Edycja {olympics?.edition}
			</Typography>
			<Typography
				variant={'h3'}
				sx={{
					color: palette.primary.main,
					mb: '10px',
				}}
			>
				{getTitle()}
			</Typography>
			<Divider />
			<Grid
				item
				container
				justifyContent={'space-between'}
				gap={{ xs: '20px', lg: 0 }}
				sx={{
					mt: 3,
				}}
			>
				<Grid
					item
					container
					xs={12}
					lg={6}
					order={{ xs: 2, lg: 1 }}
				>
					{index !== 6 && isSecretary && (
						<Button
							sx={{
								width: '230px',
							}}
							disabled={isSending}
							endIcon={isSending ? <CircularProgress size={20} /> : <FileDownloadIcon />}
							variant={'outlined'}
							onClick={() => downloadExcel()}
						>
							Pobierz raport
						</Button>
					)}
					{index === 2 && isAdmin && (
						<Button
							endIcon={<AddIcon />}
							variant={'outlined'}
							onClick={() => setOpenNewSchoolDialog(true)}
							sx={{
								width: '230px',
								ml: { xs: 0, lg: '20px' },
							}}
						>
							Nowa szkoła
						</Button>
					)}
					{index === 4 && isAdmin && (
						<Button
							endIcon={<AddIcon />}
							variant={'outlined'}
							onClick={() => setOpenNewStudentDialog(true)}
							sx={{
								width: '230px',
								ml: { xs: 0, lg: '20px' },
							}}
						>
							Nowy uczeń
						</Button>
					)}
					{isTeacher && (
						<Button
							endIcon={<PersonIcon />}
							variant={'outlined'}
							onClick={() => setOpenTeacherEditDialog(true)}
							sx={{
								width: '230px',
							}}
						>
							Dane konta
						</Button>
					)}
				</Grid>
				<Grid
					item
					container
					justifyContent={{ xs: 'space-between', lg: 'end' }}
					xs={12}
					lg={6}
					order={{ xs: 1, lg: 2 }}
				>
					{index !== 1 && index !== 6 && (
						<FormControl>
							<InputLabel>Wybierz okręg</InputLabel>
							<Select
								sx={{
									width: '230px',
								}}
								disabled={!enabledDistrictSearch}
								size={'small'}
								value={enabledDistrictSearch ? districtFilterId : districtId ? districtId : -1}
								label='Wybierz okręg'
								onChange={(event) => setDistrictFilterId(Number(event.target.value))}
							>
								<MenuItem value={-1}>Wszystkie</MenuItem>
								{districts.map((d) => (
									<MenuItem
										value={d.id}
										key={d.name}
									>
										{d.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					{isSecretary && index === 5 && (
						<>
							<FormControl>
								<InputLabel
									sx={{
										ml: { xs: 0, lg: '20px' },
									}}
								>
									Opublikowane etapy
								</InputLabel>
								<Select
									sx={{
										width: '230px',
										ml: { xs: 0, lg: '20px' },
									}}
									disabled={!isAdmin}
									size={'small'}
									value={olympics ? olympics.getPublishedStage() : STAGE_0}
									label={'Opublikwane etapy'}
									onChange={(event) => handlePublishedStageForDialog(event)}
								>
									{STAGES_TITLES.map((s: any) => (
										<MenuItem
											key={s.title}
											value={s}
											sx={{ fontSize: '16px' }}
											disabled={!!olympics && olympics.getPublishedStage().key >= s.key}
										>
											{s.title}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<ChangePublishedStageDialog
								stage={publishedStageForDialog}
								close={() => setPublishedStageForDialog(STAGE_0)}
								onSuccess={() => {
									setPublishedStageForDialog(STAGE_0);
								}}
							/>
						</>
					)}
				</Grid>
			</Grid>
			<MangedForm>
				<NewSchoolDialog
					open={openNewSchoolDialog}
					onClose={() => setOpenNewSchoolDialog(false)}
				/>
			</MangedForm>
			<MangedForm>
				<NewStudentDialog
					open={openNewStudentDialog}
					onClose={() => setOpenNewStudentDialog(false)}
				/>
			</MangedForm>
			<MangedForm>
				{openTeacherEditDialog && (
					<TeacherEditDialog
						teacherWpId={id}
						open={openTeacherEditDialog}
						onClose={() => setOpenTeacherEditDialog(false)}
					/>
				)}
			</MangedForm>
		</>
	);
};

export default PanelHeader;
