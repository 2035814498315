export const newsPages = '/aktualnosci';
export const announcementsPages = '/ogloszenia';
export const registrationPage = '/rejestracja';
export const loginPage = '/login';
export const passwordResetPage = '/reset';
export const panelPage = '/panel';
export const districtsPage = panelPage + '/okregi';
export const schoolsPage = panelPage + '/szkoly';
export const teachersPage = panelPage + '/nauczyciele';
export const studentsPage = panelPage + '/uczniowie';
export const olympicsStagesPage = panelPage + '/etapy';
export const olympicsSettingsPage = panelPage + '/ustawienia';
export const accountPage = '/konto';
