import React, { useContext, useEffect } from 'react';
import AuthContext from '../store/auth-context';
import { useNavigate } from 'react-router-dom';
import { OlympicsContextProvider } from '../store/olympics-context';
import FormsLayout from '../layout/FormsLayout';
import UserPanel from '../components/UserPanel/UserPanel';

const AccountPage = () => {
	const { role } = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (role !== 'student') {
			navigate('/');
		}
	}, [role]);

	return (
		<OlympicsContextProvider>
			<FormsLayout>
				<UserPanel />
			</FormsLayout>
		</OlympicsContextProvider>
	);
};

export default AccountPage;
