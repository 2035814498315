import { useTheme } from '@mui/material';

const CloseIcon = ({ color = '#424242' }: any) => {
	const { palette } = useTheme();

	return (
		<svg
			width='26'
			height='26'
			viewBox='0 0 26 26'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M1 1L25 25M25 1L1 25'
				stroke={color ? color : palette.secondary.main}
				strokeWidth='2'
			/>
		</svg>
	);
};

export default CloseIcon;
