export const checkSecretaryRole = (role: string) => {
	return role === 'administrator' || role === 'secretary';
};

export const checkTeacherRole = (role: string) => {
	return role === 'administrator' || role === 'secretary';
};

export const checkAdminRole = (role: string) => {};

export const DEFAULT_AUTH_ERROR = 'Nie masz uprawnień albo Twoja sesja wygasła';
export const DEFAULT_API_ERROR = 'Błąd serwera. Spróbuj za chwilę';
