import { ReactNode, useContext } from 'react';
import Footer from '../components/Footer';
import { Box, Grid, useTheme } from '@mui/material';
import SnackbarContext from '../store/snackbar-context';
import ContentContext from '../store/content-context';
import FormHeader from '../components/Header/FormHeader';
import background from '../ui/images/leavesBackground.png';
import BlockWrapper from '../components/BlockWrapper';
import CustomSnackbar from '../components/UI/CustomSnackbar';

const FormsLayout = ({ children }: { children: ReactNode }) => {
	const { settings, menu } = useContext(ContentContext);
	const { breakpoints } = useTheme();

	return (
		<>
			{/*<FormHeader menu={menu} />*/}
			<Box component={'main'}>
				<BlockWrapper
					justify='flex-start'
					lgSize={12}
					withPadding={false}
					sx={{
						height: '100%',
					}}
				>
					<Box
						component={'aside'}
						width={'32.5%'}
						height={'auto'}
						minHeight={'100vh'}
						sx={{
							background: `url(${background})`,
							[breakpoints.down('sm')]: {
								display: 'none',
							},
						}}
					/>
					<Grid
						component={'section'}
						width={'67.5%'}
						p={'137px 180px'}
						display={'flex'}
						justifyContent={'center'}
						alignItems={'center'}
						flexDirection={'column'}
						gap={'80px'}
						sx={{
							[breakpoints.down('lg')]: {
								px: '40px',
							},
							[breakpoints.down('sm')]: {
								width: '100%',
							},
						}}
					>
						{children}
					</Grid>
				</BlockWrapper>
			</Box>
			<CustomSnackbar />
			<Footer footer={settings} />
		</>
	);
};

export default FormsLayout;
