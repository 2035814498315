import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, useTheme } from '@mui/material';

type Props = {
	close: () => void;
	inProgress: boolean;
	text: string | null;
	onSuccess: () => void;
};
const ConfirmationDialog = ({ close, onSuccess, text, inProgress }: Props) => {
	const { palette } = useTheme();
	return (
		<>
			<Dialog
				open={!!text}
				onClose={() => close()}
			>
				<DialogTitle sx={{ fontSize: '24px' }}>{text}</DialogTitle>
				<DialogActions>
					<Button
						sx={{
							color: palette.secondary.main,
						}}
						onClick={() => close()}
					>
						Anuluj
					</Button>
					<Button
						sx={{
							color: palette.success.main,
						}}
						onClick={() => onSuccess()}
					>
						{inProgress ? <CircularProgress /> : 'OK'}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ConfirmationDialog;
