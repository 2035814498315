import { useTheme, Grid } from '@mui/material';

const ImgWithBg = ({ content }: any) => {
	const { palette } = useTheme();
	return (
		<Grid
			container
			sx={{
				justifyContent: 'center',
				alignItems: 'center',
				paddingY: '64px',
				paddingX: { xs: '34px', sm: '' },
				backgroundColor: content.bg_color ? content.bg_color : palette.secondary.main,
			}}
		>
			<Grid
				item
				xs={12}
				sm={10}
				lg={6}
				component='img'
				src={content.img.url}
				alt={content.img.alt}
				sx={{
					maxWidth: '100%',
					margin: '0 auto',
				}}
			/>
		</Grid>
	);
};

export default ImgWithBg;
