import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import MainButton from '../UI/MainButton';
import ExpandMenu from './ExpandMenu';
import { loginPage } from '../../store/fixed-routing';
import { NavLink } from 'react-router-dom';
import { removeTrailingSlash } from '../../utils/string-tools';
import { StyledNavLink } from './Header';
import Menu from '../../model/Menu';
import ContentContext from '../../store/content-context';

type Props = {
	menu: Menu;
	small?: boolean;
};
const FullHeader = ({ menu, small }: Props) => {
	const { palette } = useTheme();
	const { registrationEnabled } = useContext(ContentContext);
	const [show, setShow] = useState(-1);

	return (
		<Grid
			component='header'
			container
			justifyContent='center'
			alignItems='center'
			pt={small ? '12px' : '24px'}
			pb={small ? '15px' : '30px'}
			sx={{
				position: 'relative',
				width: '100%',
				backgroundColor: palette.primary.light,
				zIndex: 100,
			}}
		>
			<Grid
				component='section'
				container
				item
				justifyContent='space-between'
				alignItems='center'
				px={{ xs: 0, md: '26px', lg: 0 }}
				xs={12}
				lg={11.4}
			>
				<Grid item>
					<NavLink to={'/'}>
						<Box
							component={'img'}
							src={menu?.logo?.url}
							sx={{
								height: small ? '60px' : '100px',
								objectFit: 'cover',
							}}
						/>
					</NavLink>
				</Grid>

				<Box
					display='flex'
					gap='25px'
					flexDirection={small ? 'column' : 'column'}
					justifyContent='space-between'
					sx={{
						boxShadow: show !== -1 ? `40px -100px 0px 100px ${palette.primary.main}` : '',
						backgroundColor: show !== -1 ? palette.primary.main : 'inherit',
					}}
				>
					{/*<Box*/}
					{/*    display='flex'*/}
					{/*    gap='64px'*/}
					{/*    justifyContent='end'*/}
					{/*    alignItems='end'*/}
					{/*>*/}
					{/*<Grid item>*/}
					{/*	<Link*/}
					{/*		href={menu?.option_link?.url}*/}
					{/*		sx={{ textDecoration: 'none' }}*/}
					{/*	>*/}
					{/*		<Box*/}
					{/*			display='flex'*/}
					{/*			gap='8px'*/}
					{/*		>*/}
					{/*			<AccessibilityIcon color={show !== -1 ? palette.primary.light : palette.primary.main} />*/}
					{/*			<StyledTypography>{menu?.option_title}</StyledTypography>*/}
					{/*		</Box>*/}
					{/*	</Link>*/}
					{/*</Grid>*/}
					{/*<Grid item>*/}
					{/*	<Box sx={{ cursor: 'pointer' }}>*/}
					{/*		<StyledTypography>{menu?.language} | PL</StyledTypography>*/}
					{/*	</Box>*/}
					{/*</Grid>*/}
					{/*</Box>*/}
					<Box
						sx={{
							position: 'relative',
							display: 'flex',
							gap: '64px',
						}}
						onMouseLeave={() => setShow(-1)}
					>
						{menu?.menuItems.map((item: any, index: number) => {
							if (item?.url !== '/') {
								return (
									<Box
										key={index}
										onMouseEnter={() => {
											setShow(index);
										}}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											gap: '32px',
										}}
									>
										<StyledNavLink to={removeTrailingSlash(item.url)}>
											<Typography
												variant='subtitle2'
												sx={{
													display: 'flex',
													color: show !== -1 ? palette.primary.light : palette.primary.main,
													fontSize: '18px',
													fontWeight: 500,
													letterSpacing: '0.18px',
													borderBottom: `${show === index ? `1px solid ${palette.secondary.light}` : ''}`,
												}}
											>
												{item.title}
											</Typography>
										</StyledNavLink>
										<ExpandMenu
											menu={item.subMenu}
											signIn={menu.signIn}
											show={show === index}
										/>
									</Box>
								);
							}
						})}
						{registrationEnabled && (
							<Box>
								<Grid item>
									<MainButton
										darkVersion={show !== -1}
										title={menu?.login}
										href={loginPage}
										sx={{
											p: '8px 24px',
										}}
									/>
								</Grid>
							</Box>
						)}
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};

export default FullHeader;
