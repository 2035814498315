import { useTheme } from '@mui/material';

const MinusIcon = () => {
	const { palette } = useTheme();

	return (
		<svg
			width='24'
			height='26'
			viewBox='0 0 24 4'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M0 3.97927H24V0.0205078H0V3.97927Z'
				fill={palette.secondary.main}
			/>
		</svg>
	);
};

export default MinusIcon;
