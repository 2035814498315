class Result {
	firstStageStatus: string;
	firstStagePoints: number;
	firstStageQualified: boolean;

	secondAStageTopic: string;
	secondAOneStagePoints: number;
	secondATwoStagePoints: number;
	secondAStageQualified: boolean;

	secondBOneStagePoints: number;
	secondBTwoStagePoints: number;
	secondBThreeStagePoints: number;
	secondBStageTotalPoints: number;
	secondBStageQualified: boolean;

	thirdAStageTopic: string;
	thirdAStagePoints: number;
	thirdAStageQualified: boolean;

	thirdBOneStagePoints: number;
	thirdBTwoStagePoints: number;
	thirdBStageTotalPoints: number;
	finalTitle: string;

	constructor(
		firstStageStatus: string,
		firstStagePoints: number,
		firstStageQualified: boolean,
		secondAStageTopic: string,
		secondAOneStagePoints: number,
		secondATwoStagePoints: number,
		secondAStageQualified: boolean,
		secondBOneStagePoints: number,
		secondBTwoStagePoints: number,
		secondBThreeStagePoints: number,
		secondBStageTotalPoints: number,
		secondBStageQualified: boolean,
		thirdAStageTopic: string,
		thirdAStagePoints: number,
		thirdAStageQualified: boolean,
		thirdBOneStagePoints: number,
		thirdBTwoStagePoints: number,
		thirdBStageTotalPoints: number,
		finalTitle: string,
	) {
		this.firstStageStatus = firstStageStatus;
		this.firstStagePoints = firstStagePoints;
		this.firstStageQualified = firstStageQualified;
		this.secondAStageTopic = secondAStageTopic;
		this.secondAOneStagePoints = secondAOneStagePoints;
		this.secondATwoStagePoints = secondATwoStagePoints;
		this.secondAStageQualified = secondAStageQualified;
		this.secondBOneStagePoints = secondBOneStagePoints;
		this.secondBTwoStagePoints = secondBTwoStagePoints;
		this.secondBThreeStagePoints = secondBThreeStagePoints;
		this.secondBStageTotalPoints = secondBStageTotalPoints;
		this.secondBStageQualified = secondBStageQualified;
		this.thirdAStageTopic = thirdAStageTopic;
		this.thirdAStagePoints = thirdAStagePoints;
		this.thirdAStageQualified = thirdAStageQualified;
		this.thirdBOneStagePoints = thirdBOneStagePoints;
		this.thirdBTwoStagePoints = thirdBTwoStagePoints;
		this.thirdBStageTotalPoints = thirdBStageTotalPoints;
		this.finalTitle = finalTitle;
	}

	public static fromApiResponse(data: any) {
		return new Result(
			data.first_stage_status ? data.first_stage_status : '',
			data.first_stage_points ? data.first_stage_points : 0,
			data.first_stage_qualified ? data.first_stage_qualified : false,
			data.second_a_stage_topic ? data.second_a_stage_topic : '',
			data.second_a_one_stage_points ? data.second_a_one_stage_points : 0,
			data.second_a_two_stage_points ? data.second_a_two_stage_points : 0,
			data.second_a_stage_qualified ? data.second_a_stage_qualified : false,
			data.second_b_one_stage_points ? data.second_b_one_stage_points : 0,
			data.second_b_two_stage_points ? data.second_b_two_stage_points : 0,
			data.second_b_three_stage_points ? data.second_b_three_stage_points : 0,
			data.second_b_stage_total_points ? data.second_b_stage_total_points : 0,
			data.second_b_stage_qualified ? data.second_b_stage_qualified : false,
			data.third_a_stage_topic ? data.third_a_stage_topic : '',
			data.third_a_stage_points ? data.third_a_stage_points : 0,
			data.third_a_stage_qualified ? data.third_a_stage_qualified : 0,
			data.third_b_one_stage_points ? data.third_b_one_stage_points : 0,
			data.third_b_two_stage_points ? data.third_b_two_stage_points : 0,
			data.third_b_total_stage_points ? data.third_b_total_stage_points : 0,
			data.final_title ? data.final_title : '',
		);
	}
}

export default Result;
