import { Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import CustomTable from './CustomTable';
import { getAllSchools } from '../../api/content-api';
import SnackbarContext from '../../store/snackbar-context';
import Loader from '../../ui/Loader';
import School from '../../model/School';
import { grey } from '@mui/material/colors';
import OlympicsContext from '../../store/olympics-context';
import { filter } from './StagesTable';

const SchoolsTable = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { setMsg } = useContext(SnackbarContext);
	const { districtFilterId } = useContext(OlympicsContext);
	const [schools, setSchools] = useState<School[]>([]);
	const [displayedSchools, setDisplayedSchools] = useState<School[]>([]);

	const downloadData = () => {
		getAllSchools()
			.then((response) => {
				if (response.status === 200) {
					const schools = response.data.map((s: any) => School.fromApiResponse(s));
					setSchools(schools);
					setDisplayedSchools(filter(schools, districtFilterId));
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
				setIsLoading(false);
			})
			.catch((exception) => {
				setIsLoading(false);
				console.error(exception);
				setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
			});
	};

	useEffect(() => {
		setDisplayedSchools(filter(schools, districtFilterId));
	}, [districtFilterId]);

	useEffect(() => {
		downloadData();
	}, []);

	const data = (input: any) => {
		return {
			columns: [
				{ header: 'Nazwa', accessor: 'name', align: 'left', width: '25%', bgColor: grey[100] },
				{ header: 'Miasto', accessor: 'city', align: 'center', bgColor: grey[100] },
				{ header: 'Kod pocztowy', accessor: 'zipCode', align: 'center', bgColor: grey[100] },
				{ header: 'Ulica', accessor: 'street', align: 'center', bgColor: grey[100] },
				{ header: 'Numer', accessor: 'number', align: 'center', bgColor: grey[100] },
				{ header: 'Okręg', accessor: 'district', align: 'center', bgColor: grey[100] },
			],

			rows: input
				? input.map((i: School) => {
						return {
							name: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.name}
								</Typography>
							),
							city: <Typography variant='caption'>{i.city}</Typography>,
							zipCode: <Typography variant='caption'>{i.zipCode}</Typography>,
							street: <Typography variant='caption'>{i.street}</Typography>,
							number: (
								<Typography variant='caption'>
									{i.building + (i.apartment !== '' ? ` / ${i.apartment}` : '')}
								</Typography>
							),
							district: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.district.name}
								</Typography>
							),
						};
					})
				: [],
		};
	};

	return <>{isLoading ? <Loader /> : <CustomTable data={data(displayedSchools)} />}</>;
};

export default SchoolsTable;
