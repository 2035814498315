import District from './District';
import Result from './Result';
import School from './School';
import Specialization from './Specialization';
import Teacher from './Teacher';

class Student {
	id: number;
	personalTitle: string;
	firstName: string;
	lastName: string;
	schoolClass: number;
	email: string;
	phone: string;
	approved: boolean;
	specialCertificate: boolean;
	city: string;
	zipCode: string;
	street: string;
	building: string;
	apartment: string;
	district: District;
	school: School;
	specialization: Specialization;
	teachers: Teacher[];
	result: Result;
	
	certEmail: string;
	certStreet: string;
	certBuilding: string;
	certApartment: string;
	certZipCode: string;
	certCity: string;
	declarationsIds: [];
	declarations: [];
	birthdate: string | null;
	birthdatePlace: string | null;
	topic: any;

	constructor(
		id: number,
		personalTitle: string,
		firstName: string,
		lastName: string,
		schoolClass: number,
		email: string,
		phone: string,
		approved: boolean,
		specialCertificate: boolean,
		city: string,
		zipCode: string,
		street: string,
		building: string,
		apartment: string,
		district: District,
		school: School,
		specialization: Specialization,
		teachers: Teacher[],
		result: Result,
		certEmail: string,
		certStreet: string,
		certBuilding: string,
		certApartment: string,
		certZipCode: string,
		certCity: string,
		declarationsIds: [],
		declarations: [],
		birthdate: string | null,
		birthdatePlace: string | null,
		topic: any,
	) {
		this.id = id;
		this.personalTitle = personalTitle;
		this.firstName = firstName;
		this.lastName = lastName;
		this.schoolClass = schoolClass;
		this.email = email;
		this.phone = phone;
		this.approved = approved;
		this.specialCertificate = specialCertificate;
		this.city = city;
		this.zipCode = zipCode;
		this.street = street;
		this.building = building;
		this.apartment = apartment;
		this.district = district;
		this.school = school;
		this.specialization = specialization;
		this.teachers = teachers;
		this.result = result;
		this.certEmail = certEmail;
		this.certStreet = certStreet;
		this.certBuilding = certBuilding
		this.certApartment = certApartment;
		this.certZipCode = certZipCode;
		this.certCity = certCity;
		this.declarationsIds = declarationsIds;
		this.declarations = declarations;
		this.birthdate = birthdate;
		this.birthdatePlace = birthdatePlace;
		this.topic = topic;
	}

	public static fromApiResponse(data: any) {
		return new Student(
			data.id ? data.id : 0,
			data.personal_title ? data.personal_title : '',
			data.first_name ? data.first_name : '',
			data.last_name ? data.last_name : '',
			data.class ? data.class : 1,
			data.email ? data.email : '',
			data.phone ? data.phone : '',
			data.approved === 1,
			data.special_certificate === 1,
			data.city ? data.city : '',
			data.zip_code ? data.zip_code : '',
			data.street ? data.street : '',
			data.building ? data.building : '',
			data.apartment ? data.apartment : '',
			District.fromApiResponse(data.district ? data.district : {}),
			School.fromApiResponse(data.school ? data.school : {}),
			Specialization.fromApiResponse(data.specialization ? data.specialization : {}),
			data.teachers ? data.teachers.map((t: any) => Teacher.fromApiResponse(t)) : [],
			Result.fromApiResponse(data.results ? data.results : {}),
			data.cert_email ? data.cert_email :  '',
			data.cert_street ? data.cert_street : '',
			data.cert_building ? data.cert_building : '',
			data.cert_apartment ? data.cert_apartment : '',
			data.cert_zip_code ? data.cert_zip_code : '',
			data.cert_city ? data.cert_city : '',
			data.declarations ? data.declarations.map((d: any) => d.id) : [],
			data.declarations ? data.declarations.map((d: any) => d.short_title) : [],
			data.birthday ? data.birthday : null,
			data.birthday_place ? data.birthday_place : null,
			{
				category: data.stage_three_b_oral_category,
				topic: data.stage_three_b_oral_topic,
				linguisticTopic: data.stage_three_b_oral_linguistic_topic
			}
		);
	}
}

export default Student;
