import { Box } from '@mui/material';
import BlockWrapper from '../components/BlockWrapper';
import HighlightedTextWysiwyg from '../components/UI/HighlightedTextWysiwyg';
import Wysiwyg from '../components/UI/Wysiwyg';

const Title = ({ content }: any) => {
	return (
		<BlockWrapper
			smSize={10}
			lgSize={6}
			xlSize={6}
			justify={'center'}
			withPadding={false}
			sx={{
				flexDirection: 'column',
				alignItems: 'flex-start',
				padding: { xs: '0 26px', md: '' },
			}}
		>
			<Box
				display='flex'
				flexDirection='column'
				alignItems='center'
				justifyContent='center'
				paddingTop='70px'
			>
				{content.title &&
					(content.highlighted_title !== 'simple' ? (
						<HighlightedTextWysiwyg
							text={content.title}
							rotate={false}
							sx={{
								alignSelf: 'flex-start',
								pb: '16px',
								fontWeight: '500',
								letterSpacing: '0.32px',
							}}
							variantSize={'h3'}
						/>
					) : (
						<Wysiwyg
							text={content.title}
							fontWeight={'500'}
							variantSize={'h3'}
							sx={{
								alignSelf: 'flex-start',
								pb: '16px',
								letterSpacing: '0.32px',
								margin: '0px',
							}}
						/>
					))}
			</Box>
		</BlockWrapper>
	);
};

export default Title;
