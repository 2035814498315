import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './styles/theme';
import './index.css';
import { SnackbarContextProvider } from './store/snackbar-context';
import { ContentContextProvider } from './store/content-context';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './store/auth-context';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<SnackbarContextProvider>
					<AuthContextProvider>
						<ContentContextProvider>
							<CssBaseline />
							<App />
						</ContentContextProvider>
					</AuthContextProvider>
				</SnackbarContextProvider>
			</BrowserRouter>
		</ThemeProvider>
	</React.StrictMode>,
);
