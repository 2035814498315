import { useTheme, Grid, styled, Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { removeTrailingSlash } from '../../utils/string-tools';

type Props = {
	pages: any;
};

const LinkStyled = styled(Link)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	fontWeight: 400,
	lineSpacing: '0.16px',
	color: theme.palette.primary.main,
	textDecoration: 'none',
	fontSize: '16px',
}));

const Breadcrumbs = ({ pages }: Props) => {
	const { palette } = useTheme();
	const location = useLocation();

	if (location.pathname === '/') {
		return <></>;
	}
	const filteredPage = pages.find(
		(item: any) => removeTrailingSlash(item.link) == removeTrailingSlash(location.pathname),
	);

	return (
		<>
			{filteredPage && (
				<Grid
					container
					justifyContent={'center'}
					mt={'40px'}
					mb={'20px'}
					sx={{
						color: 'pink',
					}}
					display={filteredPage[0]?.link !== '/' ? 'flex' : 'none'}
				>
					<Grid
						item
						xs={12}
						lg={11.4}
						display={'flex'}
						flexWrap={'wrap'}
						gap={'8px'}
						px={{ xs: '26px', lg: 0 }}
					>
						<LinkStyled to={'/'}>Strona główna</LinkStyled>
						<Box
							component='span'
							sx={{
								fontWeight: 400,
								lineSpacing: '0.16px',
								color: palette.primary.main,
								textDecoration: 'none',
								transition: 'all .1s ease',
								fontSize: '16px',
							}}
						>
							/
						</Box>
						{filteredPage.breadcrumbs.length > 0 &&
							filteredPage.breadcrumbs.map((crumb: any, index: number) => (
								<Box
									display='flex'
									gap='8px'
									key={index}
								>
									<LinkStyled to={crumb.link}>{crumb.title}</LinkStyled>
									<Box
										component='span'
										sx={{
											fontWeight: 400,
											lineSpacing: '0.16px',
											color: palette.primary.main,
											textDecoration: 'none',
											transition: 'all .1s ease',
											fontSize: '16px',
										}}
									>
										/
									</Box>
								</Box>
							))}
						<LinkStyled to={filteredPage.link}>{filteredPage.title}</LinkStyled>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default Breadcrumbs;
