import { Box, useTheme } from '@mui/material';
import ArrowRight from '../../ui/icons/ArrowRight';

type Props = {
	onClick?: any;
	currentPage?: any;
	setCurrentPage?: any;
	bgcolor?: any;
	color?: any;
	sx?: any;
};

const NextPageArrow = ({ onClick, currentPage, setCurrentPage, bgcolor, color, sx }: Props) => {
	const { breakpoints } = useTheme();

	const handleClick = onClick
		? () => {
				onClick();
		  }
		: () => {
				setCurrentPage(currentPage + 1);
		  };

	return (
		<Box
			sx={{
				width: '140px',
				height: '80px',
				borderRadius: '100px 0 0 100px',
				bgcolor: `${bgcolor ? bgcolor : 'rgba(255,255,255,0.6)'}`,
				cursor: 'pointer',
				'& * path': {
					fill: color && color,
				},
				[breakpoints.down('sm')]: {
					width: '92px',
					height: '62px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				},
				...sx,
			}}
			onClick={handleClick}
		>
			<ArrowRight />
		</Box>
	);
};

export default NextPageArrow;
