import { useContext, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import MainButton from '../UI/MainButton';
import HideInputIcon from '../UI/HideInputIcon';
import SnackbarContext from '../../store/snackbar-context';
import FormsLayout from '../../layout/FormsLayout';
import FormTextField from '../UI/FormTextField';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginPage } from '../../store/fixed-routing';
import { sendResetPassword } from '../../api/auth-api';

const formErrors = {
	passwordError: 'Wprowadź hasło',
	incorrectPassword: 'Twoje hasło powinno posiadać przynajmniej 5 znaków i jedną cyfrę',
	incorrectConfirmation: 'Wpisane hasła nie zgadzają się',
};

const ResetPasswordForm = () => {
	const { palette, typography, breakpoints } = useTheme();
	const navigate = useNavigate();
	const location = useLocation();

	const [showPassword, setShowPassword] = useState(false);
	const [validated, setValidated] = useState(false);
	const { setMsg } = useContext(SnackbarContext);
	const [formInfo, setFormInfo] = useState({
		login: '',
		key: '',
		password: '',
		passwordConfirmation: '',
	});

	const [formValidation, setFormValidation] = useState(formErrors);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		setFormInfo((prevState: any) => {
			return {
				...prevState,
				key: searchParams.get('key'),
				login: searchParams.get('login'),
			};
		});
	}, []);

	const passwordHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				passwordError: formErrors.passwordError,
			});
		} else if (!/\d/.test(value) || value.length <= 5) {
			setFormValidation({
				...formValidation,
				passwordError: formErrors.incorrectPassword,
			});
		} else {
			setFormValidation({
				...formValidation,
				passwordError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				password: value,
			};
		});
	};

	const passwordConfirmationHandler = (value: string) => {
		setFormInfo((prevState) => {
			return {
				...prevState,
				passwordConfirmation: value,
			};
		});
	};

	const actionSend = (event: { preventDefault: () => void }) => {
		setValidated(true);
		event.preventDefault();
		if (formValidation.passwordError !== '') {
			setMsg({ msg: formValidation.passwordError, severity: 'warning' });
			return;
		}
		if (formInfo.password !== formInfo.passwordConfirmation) {
			setMsg({ msg: formErrors.incorrectConfirmation, severity: 'warning' });
			return;
		}
		sendResetPassword(formInfo.login, formInfo.key, formInfo.password)
			.then((res) => {
				setMsg({ msg: 'Hasło zostało zmienione. Zaloguj się', severity: 'info' });
			})
			.catch((error) =>
				setMsg({ msg: 'Wystąpił błąd przy resetowaniu hasła. Spróbuj ponownie później.', severity: 'error' }),
			);
	};

	return (
		<FormsLayout>
			<Grid
				width={'100%'}
				maxWidth={'650px'}
				display={'flex'}
				flexDirection={'column'}
				gap={'24px'}
			>
				<Typography
					variant={'h3'}
					fontWeight={'500'}
					color={palette.primary.main}
				>
					Resetuj hasło
				</Typography>
				<Grid
					component={'form'}
					display={'flex'}
					flexDirection={'column'}
				>
					<FormTextField
						id={'password'}
						type={!showPassword ? 'password' : ''}
						label={'Nowe Hasło'}
						validated={validated}
						errorMsg={formValidation.passwordError}
						value={formInfo.password}
						valueChangeHandler={passwordHandler}
						endIconElement={
							<IconButton
								sx={{
									padding: 0,
									'& svg': {
										minHeight: '30px',
										minWidth: '30px',
									},
								}}
								onClick={() => {
									setShowPassword(!showPassword);
								}}
							>
								<HideInputIcon />
							</IconButton>
						}
					/>
					<FormTextField
						id={'password-confirmation'}
						type={'password'}
						label={'Potwierdź hasło'}
						validated={validated}
						errorMsg={formValidation.passwordError}
						value={formInfo.passwordConfirmation}
						valueChangeHandler={passwordConfirmationHandler}
						labelSx={{
							mt: '16px',
						}}
					/>
					<Typography
						sx={{
							fontSize: typography.h6.fontSize,
							fontWeight: '400',
							textDecoration: 'underline',
							alignSelf: 'flex-end',
							color: palette.primary.main,
							cursor: 'pointer',
							mt: '4px',
							mb: '32px',
							[breakpoints.down('md')]: {
								fontSize: `${typography.h5.fontSize}`,
							},
						}}
						onClick={() => navigate(loginPage)}
					>
						Zaloguj się
					</Typography>
					<Box
						alignSelf={'center'}
						mt={'16px'}
						onClick={actionSend}
					>
						<MainButton
							title={'Resetuj hasło'}
							darkVersion={true}
							sx={{
								alignSelf: 'center',
								lineHeight: '20px',
								letterSpacing: '0.18px',
							}}
						/>
					</Box>
				</Grid>
			</Grid>
		</FormsLayout>
	);
};

export default ResetPasswordForm;
