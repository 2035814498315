import Student from '../../../model/Student';
import { Checkbox, FormControl, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import CustomTable from '../CustomTable';
import { useContext, useEffect, useState } from 'react';
import { getFirstStageStudents, updateFirstStageResult } from '../../../api/content-api';
import { loginPage } from '../../../store/fixed-routing';
import AuthContext from '../../../store/auth-context';
import SnackbarContext from '../../../store/snackbar-context';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../ui/Loader';
import ActionButtons from './ActionButtons';
import OlympicsContext from '../../../store/olympics-context';
import { DEFAULT_API_ERROR, DEFAULT_AUTH_ERROR } from '../../../utils/auth-tools';
import { filter } from '../StagesTable';

const FirstStageTable = () => {
	const [allStudents, setAllStudents] = useState([]);
	const [displayedStudents, setDisplayedStudents] = useState<Student[]>([]);
	const [editedStudent, setEditedStudent] = useState<Student>();
	const [isLoading, setIsLoading] = useState(true);
	const [isUpdating, setIsUpdating] = useState(false);
	const { logout, token, isAdmin } = useContext(AuthContext);
	const { olympics, refresh, districtFilterId } = useContext(OlympicsContext);
	const { setMsg } = useContext(SnackbarContext);
	const navigate = useNavigate();
	const editable = olympics && (isAdmin || !olympics?.firstStagePublished);

	const getStatusOptionsLabel = (key: string) => {
		switch (key) {
			case 'delivered':
				return 'Dostarczono';
			case 'not-delivered':
				return 'Nie dostarczono';
			default:
				return '';
		}
	};

	useEffect(() => {
		setDisplayedStudents(filter(allStudents, districtFilterId));
	}, [districtFilterId]);

	const downloadData = () => {
		getFirstStageStudents(token)
			.then((response) => {
				if (response.status === 200) {
					const students = response.data.map((d: any) => Student.fromApiResponse(d));
					setAllStudents(students);
					setDisplayedStudents(filter(students, districtFilterId));
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
				setIsLoading(false);
			})
			.catch((exception) => {
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: DEFAULT_AUTH_ERROR });
				} else {
					const msg = exception?.response?.data?.message ? exception?.response?.data?.message : DEFAULT_API_ERROR;
					setMsg({ msg });
				}
				setIsLoading(false);
			});
	};

	const handleSaveFirstStageResult = () => {
		setIsUpdating(true);
		const data = {
			id: editedStudent?.id,
			firstStageStatus: editedStudent?.result.firstStageStatus,
			firstStagePoints: editedStudent?.result.firstStagePoints,
			firstStageQualified: editedStudent?.result.firstStageQualified,
		};
		updateFirstStageResult(token, data)
			.then((response) => {
				setIsUpdating(false);
				if (response.status === 200) {
					const students = response.data.map((d: any) => Student.fromApiResponse(d));
					setAllStudents(students);
					setDisplayedStudents(filter(students, districtFilterId));
					setEditedStudent(undefined);
					setMsg({ msg: 'Dane ucznia zostały zaktualizowane', severity: 'success' });
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
				setIsLoading(false);
			})
			.catch((exception) => {
				setIsUpdating(false);
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: DEFAULT_AUTH_ERROR });
				} else {
					const msg = exception?.response?.data?.message ? exception?.response?.data?.message : DEFAULT_API_ERROR;
					setMsg({ msg });
				}
				setIsLoading(false);
			});
	};

	useEffect(() => {
		refresh();
		downloadData();
	}, []);

	const handleStatusChange = (event: SelectChangeEvent) => {
		if (editedStudent) {
			setEditedStudent({
				...editedStudent,
				result: { ...editedStudent.result, firstStageStatus: event.target.value },
			});
		}
	};

	const handlePointsChange = (event: any) => {
		if (editedStudent) {
			setEditedStudent({
				...editedStudent,
				result: { ...editedStudent.result, firstStagePoints: Number(event.target.value) },
			});
		}
	};

	const handleQualifiedChange = () => {
		if (editedStudent) {
			setEditedStudent({
				...editedStudent,
				result: { ...editedStudent.result, firstStageQualified: !editedStudent.result.firstStageQualified },
			});
		}
	};

	const renderStatusField = (i: Student) => {
		return editedStudent?.id !== i.id ? (
			<Typography
				variant='caption'
				fontWeight='medium'
			>
				{getStatusOptionsLabel(i.result.firstStageStatus)}
			</Typography>
		) : (
			<FormControl
				variant='standard'
				sx={{
					width: '100%',
					fontSize: '16px',
					'.MuiSelect-standard': {
						fontSize: '16px',
					},
				}}
			>
				<Select
					value={editedStudent?.result?.firstStageStatus}
					onChange={handleStatusChange}
				>
					<MenuItem
						value={'delivered'}
						sx={{ fontSize: '16px' }}
					>
						{getStatusOptionsLabel('delivered')}
					</MenuItem>
					<MenuItem
						value={'not-delivered'}
						sx={{ fontSize: '16px' }}
					>
						{getStatusOptionsLabel('not-delivered')}
					</MenuItem>
				</Select>
			</FormControl>
		);
	};

	const renderPointsField = (i: Student) => {
		return editedStudent?.id === i.id ? (
			<TextField
				variant={'standard'}
				type={'number'}
				value={editedStudent.result.firstStagePoints}
				sx={{
					width: '100%',
					height: '100%',
					justifyContent: 'end',
					'& input': {
						pl: '7%',
						minHeight: '100%',
						margin: 0,
						fontSize: '16px',
						textAlign: 'center',
					},
				}}
				onChange={handlePointsChange}
			/>
		) : (
			<Typography
				variant='caption'
				fontWeight='medium'
			>
				{i.result.firstStagePoints}
			</Typography>
		);
	};

	const renderQualifiedField = (i: Student) => {
		const value = editedStudent?.id === i.id ? editedStudent.result.firstStageQualified : i.result.firstStageQualified;
		return (
			<Checkbox
				checked={value}
				disabled={editedStudent?.id !== i.id}
				onChange={handleQualifiedChange}
			/>
		);
	};

	const data = (input: any) => {
		return {
			columns: [
				{ header: 'Lp.', accessor: 'index', align: 'left', width: '60px' },
				{ header: 'Tytuł', accessor: 'personalTitle', align: 'left', width: '100px' },
				{ header: 'Imię', accessor: 'firstName', align: 'left' },
				{ header: 'Nazwisko', accessor: 'lastName', align: 'left' },
				{ header: 'Szkoła', accessor: 'school', align: 'left' },
				{ header: 'Klasa', accessor: 'schoolClass', width: '100px', align: 'center' },
				{ header: 'Specjalizacja', accessor: 'specialization', width: '100px', align: 'center' },
				{ header: 'Status pracy', accessor: 'status', width: '180px', align: 'center', editable: editable },
				{ header: 'Punkty', accessor: 'points', width: '100px', align: 'center', editable: editable },
				{ header: 'Zakwalifikowany', accessor: 'qualified', width: '150px', align: 'center', editable: editable },
				{ header: '', accessor: 'action', align: 'center', width: '100px', nosort: true, editable: editable },
			],

			rows: input
				? input.map((i: Student, index: number) => {
						return {
							index: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{index + 1}
								</Typography>
							),
							personalTitle: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.personalTitle}
								</Typography>
							),
							firstName: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.firstName}
								</Typography>
							),
							lastName: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.lastName}
								</Typography>
							),
							school: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.school.name}, {i.school.city}
								</Typography>
							),
							schoolClass: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.schoolClass}
								</Typography>
							),
							specialization: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.specialization.name}
								</Typography>
							),
							status: renderStatusField(i),
							points: renderPointsField(i),
							qualified: renderQualifiedField(i),
							action: (
								<ActionButtons
									student={i}
									disabled={!editable}
									editedStudent={editedStudent}
									inProgress={isUpdating}
									handleSave={handleSaveFirstStageResult}
									handleClose={() => setEditedStudent(undefined)}
									handleEdit={() => setEditedStudent(i)}
								/>
							),
						};
				  })
				: [],
		};
	};
	return <>{isLoading ? <Loader /> : <CustomTable data={data(displayedStudents)} />}</>;
};

export default FirstStageTable;
