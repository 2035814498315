import { Box, styled, Typography, useTheme } from '@mui/material';
import { keyframes } from '@emotion/react';

type Props = {
	content: any;
};
const FullWidthText = ({ content }: Props) => {
	const { breakpoints, palette } = useTheme();
	const text = content.text ? content.text : '';

	const slide = keyframes`
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    `;

	return (
		<Box
			overflow={'hidden'}
			component={'section'}
			sx={{
				backgroundColor: content.background_color ? content.background_color : palette.secondary.light,
				height: '216px',
				display: 'flex',
				alignItems: 'center',
				position: 'relative',
				py: '74px',
				[breakpoints.down('md')]: {
					py: '56px',
				},
			}}
		>
			<Box
				display={'flex'}
				alignItems={'center'}
				justifyContent={'flex-end'}
				sx={{
					position: 'relative',
					animation: `${slide} 20s linear infinite`,
					width: '100%',
				}}
			>
				<Typography
					component={'span'}
					variant={'h1'}
					sx={{
						display: 'inline-block',
						color: content.font_color && content.font_color,
						fontWeight: 500,
						whiteSpace: 'nowrap',
					}}
				>
					{text}
				</Typography>
			</Box>
		</Box>
	);
};

export default FullWidthText;
