import { Checkbox, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import CustomTable from './CustomTable';
import { getAllTeachers } from '../../api/content-api';
import SnackbarContext from '../../store/snackbar-context';
import Loader from '../../ui/Loader';
import Teacher from '../../model/Teacher';
import AuthContext from '../../store/auth-context';
import { useNavigate } from 'react-router-dom';
import { loginPage } from '../../store/fixed-routing';
import ApproveTeacherDialog from './ApproveTeacherDialog';
import OlympicsContext from '../../store/olympics-context';
import dayjs from 'dayjs';
import { filter } from './StagesTable';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { MangedForm } from '../../ui/Form/MangedForm';
import TeacherEditDialog from './TeacherEditDialog';

const TeachersTable = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { palette } = useTheme();
	const navigate = useNavigate();
	const { setMsg } = useContext(SnackbarContext);
	const { logout, token, isAdmin } = useContext(AuthContext);
	const { olympics, districtFilterId } = useContext(OlympicsContext);
	const [teachers, setTeachers] = useState<Teacher[]>([]);
	const [displayedTeachers, setDisplayedTeachers] = useState<Teacher[]>([]);
	const [approveTeacher, setApproveTeacher] = useState<Teacher | null>();
	const editable = isAdmin || olympics?.registrationConfirmationFinishDate?.isAfter(dayjs());
	const [editedTeacherId, setEditedTeacherId] = useState<number | null>(null);

	const downloadData = () => {
		getAllTeachers(token)
			.then((response) => {
				if (response.status === 200) {
					const teachers = response.data.map((d: any) => Teacher.fromApiResponse(d));
					setTeachers(teachers);
					setDisplayedTeachers(filter(teachers, districtFilterId));
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
				setIsLoading(false);
			})
			.catch((exception) => {
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
				} else {
					setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
				}
				setIsLoading(false);
			});
	};

	useEffect(() => {
		downloadData();
	}, []);

	useEffect(() => {
		setDisplayedTeachers(filter(teachers, districtFilterId));
	}, [districtFilterId]);

	const data = (input: any) => {
		return {
			columns: [
				{ header: 'Potwierdzony', accessor: 'approved', align: 'center', width: '150px', editable: editable },
				{ header: 'Tytuł', accessor: 'personalTitle', align: 'left', width: '100px' },
				{ header: 'Stopień naukowy', accessor: 'academicDegree', align: 'left' },
				{ header: 'Imię', accessor: 'firstName', align: 'left' },
				{ header: 'Nazwisko', accessor: 'lastName', align: 'left' },
				{ header: 'Afiliacja', accessor: 'affiliation', align: 'left' },
				{ header: 'Adres', accessor: 'address', align: 'left' },
				{ header: 'Dane kontaktowe', accessor: 'contactDetails', align: 'left' },
				{ header: 'Okręg', accessor: 'district', align: 'left' },
				{ header: '', accessor: 'edit', width: '100px', nosort: true, editable: isAdmin },
			],

			rows: input
				? input.map((i: Teacher) => {
						return {
							approved: (
								<Checkbox
									checked={i.approved}
									disabled={i.approved || !editable}
									onChange={(event) => setApproveTeacher(i)}
								/>
							),
							personalTitle: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.personalTitle}
								</Typography>
							),
							academicDegree: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.academicDegree}
								</Typography>
							),
							firstName: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.firstName}
								</Typography>
							),
							lastName: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.lastName}
								</Typography>
							),
							address: (
								<Typography variant='caption'>
									{`${i.city}, ${i.zipCode}, ${i.street} ${i.building} ${i.apartment !== '' ? '/' + i.apartment : ''}`}
								</Typography>
							),
							contactDetails: <Typography variant='caption'>{`email: ${i.email}, telefon: ${i.phone}`}</Typography>,
							affiliation: <Typography variant='caption'>{i.affiliation}</Typography>,
							district: <Typography variant='caption'>{i.district?.name ? i.district?.name : 'Nieznany'}</Typography>,
							edit: (
								<Tooltip title={'Edytuj'}>
									<IconButton
										onClick={() => setEditedTeacherId(i.wpUserId)}
										sx={{
											padding: 0,
											width: '100%',
										}}
									>
										<ModeEditIcon fontSize={'small'} />
									</IconButton>
								</Tooltip>
							),
						};
				  })
				: [],
		};
	};

	return (
		<>
			{isLoading ? <Loader /> : <CustomTable data={data(displayedTeachers)} />}
			<ApproveTeacherDialog
				teacher={approveTeacher}
				close={() => setApproveTeacher(null)}
				onSuccess={() => {
					setApproveTeacher(null);
					downloadData();
				}}
			/>
			<MangedForm>
				{editedTeacherId && (
					<TeacherEditDialog
						teacherWpId={editedTeacherId}
						open={!!editedTeacherId}
						onClose={() => setEditedTeacherId(null)}
					/>
				)}
			</MangedForm>
		</>
	);
};

export default TeachersTable;
