import React from "react";
import { cleanUpHtml } from "../../utils/string-tools";
import { Typography, useTheme } from "@mui/material";

type Props = {
  text: string;
  fontWeight: string;
  variantSize?: any;
  sx?: any;
};

const Wysiwyg = ({ text, fontWeight, variantSize, sx }: Props) => {
  const { palette, breakpoints } = useTheme();

  if (text) {
    return (
      <Typography
        component={"div"}
        variant={variantSize && variantSize}
        sx={{
          color: palette.primary.main,
          fontWeight: fontWeight,
          position: "relative",
          "& *": {
            display: "inline-block",
            m: "0",
            p: "0",
          },
          "& ul": {
            padding: 0,
          },
          "& li": {
            margin: "20px 0 20px 10px",
          },
          [breakpoints.down("lg")]: {
            "& *": { whiteSpace: "normal" },
          },
          ...sx,
        }}
        dangerouslySetInnerHTML={{
          __html: cleanUpHtml(text),
        }}
      />
    );
  } else return <></>;
};

export default Wysiwyg;
