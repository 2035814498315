class District {
	id: number;
	name: string;

	constructor(id: number, name: string) {
		this.id = id;
		this.name = name;
	}

	public static fromApiResponse(data: any) {
		return new District(data.id ? data.id : 0, data.name ? data.name : '');
	}
}

export default District;
