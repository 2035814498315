import FullHeader from './FullHeader';
import StickyHeader from './StickyHeader';
import MinHeader from './MinHeader';
import { styled, useMediaQuery } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
	textDecoration: 'none',
	'&:hover': {
		borderBottom: `1px solid ${theme.palette.secondary.light}`,
	},
	'&:hover p': {
		marginBottom: '-1px',
		borderBottom: `1px solid ${theme.palette.secondary.light}`,
	},
	'&.active': {
		borderBottom: `1px solid ${theme.palette.secondary.light}`,
	},
	'&.active p': {
		borderBottom: `1px solid ${theme.palette.secondary.light}`,
	},
}));
const Header = ({ menu }: any) => {
	const matches = useMediaQuery('(min-width: 1300px)');
	return (
		<>
			{matches ? <FullHeader menu={menu} /> : <MinHeader menu={menu} />}
			<StickyHeader menu={menu} />
		</>
	);
};

export default Header;
