import { Box, useTheme } from '@mui/material';
import BlockWrapper from '../components/BlockWrapper';
import { useState } from 'react';
import Leaf from '../ui/icons/Leaf';
import PreviousPageArrow from '../components/UI/PreviousPageArrow';
import NextPageArrow from '../components/UI/NextPageArrow';

type Props = {
	content: any;
};

const FullWidthGallery = ({ content }: Props) => {
	const { palette, breakpoints, typography } = useTheme();
	const [currentImage, setCurrentImage] = useState(0);
	const gallery = content.gallery && content.gallery;

	return (
		<BlockWrapper
			justify={'center'}
			lgSize={12}
			withPadding={false}
			sx={{
				position: 'relative',
				flexDirection: 'column',
				mb: '40px',
			}}
		>
			{gallery && (
				<Box
					component={'img'}
					src={gallery[currentImage].url}
					alt={gallery[currentImage].alt}
					height={'80vh'}
					width={'100%'}
					sx={{
						objectFit: 'cover',
						objectPosition: 'top',
						[breakpoints.down('md')]: {
							height: '40vh',
						},
					}}
				/>
			)}
			<PreviousPageArrow
				currentPage={currentImage}
				setCurrentPage={setCurrentImage}
				bgcolor={palette.secondary.main}
				sx={{
					display: `${currentImage !== 0 ? 'block' : 'none'}`,
					position: 'absolute',
				}}
			/>
			<NextPageArrow
				currentPage={currentImage}
				setCurrentPage={setCurrentImage}
				bgcolor={palette.secondary.main}
				sx={{
					display: `${currentImage !== gallery.length - 1 ? 'block' : 'none'}`,
					position: 'absolute',
					right: '0',
				}}
			/>
			<Box
				component={'aside'}
				position={'absolute'}
				bottom={'-80px'}
				right={'0'}
				left={'0'}
				mx={'auto'}
				display={'flex'}
				justifyContent={'center'}
				zIndex={1}
			>
				<Leaf />
			</Box>
		</BlockWrapper>
	);
};

export default FullWidthGallery;
