import { Alert, Snackbar } from '@mui/material';
import { useContext } from 'react';
import SnackbarContext from '../../store/snackbar-context';

const CustomSnackbar = () => {
	const { msg, severity, setMsg } = useContext(SnackbarContext);

	return (
		<Snackbar
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			open={msg != ''}
			onClose={() => setMsg({ msg: '' })}
			sx={{
				fontSize: '28px',
				maxWidth: '40%',
				'.MuiSnackbar-root': {
					fontSize: '28px',
					width: '600px',
				},
			}}
		>
			<Alert
				severity={severity}
				sx={{
					fontSize: '28px',
					svg: {
						width: '40px',
						height: '40px',
					},
				}}
			>
				{msg}
			</Alert>
		</Snackbar>
	);
};

export default CustomSnackbar;
