import { useTheme, Grid, Typography, Box, Divider, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { cleanUpHtml, formatAddress, getRelativeLink } from '../utils/string-tools';

const StyledTypography = styled(Typography)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontSize: '18px',
	fontWeight: 400,
	letterSpacing: '0.18px',
}));

const Footer = ({ footer }: any) => {
	const cleanText = footer?.additional_footer?.text_first_column
		? cleanUpHtml(footer.additional_footer.text_first_column)
		: '';
	const { palette } = useTheme();

	return (
		<Grid
			component='footer'
			container
			item
			xs={12}
			justifyContent={{ xs: 'center', lg: 'left' }}
			sx={{ padding: '40px' }}
		>
			<Typography
				sx={{
					paddingBottom: { xs: '60px', md: '80px' },
					color: palette.primary.main,
					fontSize: '48px',
					fontWeight: 500,
					letterSpacing: '0.48px',
					textAlign: { xs: 'center', lg: 'left' },
				}}
			>
				{footer?.quote}
			</Typography>
			<Grid
				component='section'
				container
				item
				xs={12}
				gap={{ xs: '16px', lg: '0' }}
				flexDirection={{ xs: 'column', sm: 'row' }}
				justifyContent={'space-between'}
				flexWrap={{ xs: 'nowrap', sm: 'wrap' }}
			>
				<Grid
					item
					xs={12}
					lg={4.5}
					pb={{ xs: '40px', lg: '0' }}
					textAlign={{ xs: 'center', lg: 'left' }}
				>
					<Link
						to={'/'}
						style={{ textDecoration: 'none' }}
					>
						<Box
							component='img'
							alt='logo'
							src={footer?.company_logo?.url}
							sx={{ width: { xs: '90%', sm: 'auto' }, maxWidth: '305px' }}
						/>
					</Link>
					<Box
						pt='40px'
						pb='16px'
						width={{ sm: '366px' }}
						margin={{ xs: '0 auto', lg: '0' }}
					>
						<StyledTypography>{footer.address.firstLine}</StyledTypography>
						<StyledTypography sx={{ width: '90%', margin: { xs: '0 auto', lg: '0' } }}>
							{formatAddress(footer.address)}
						</StyledTypography>
					</Box>
					<Box>
						<StyledTypography>
							{footer?.email?.prefix ? `${footer?.email?.prefix}:` : ''}
							<Box
								component='span'
								onClick={() => (window.location.href = 'mailto:' + footer?.email?.email.replace(/[\u2028]/g, ''))}
								sx={{ textDecoration: 'underline', cursor: 'pointer' }}
							>
								{footer?.email?.email.replace(/[\u2028]/g, '')}{' '}
							</Box>
						</StyledTypography>
						<StyledTypography>
							{footer?.phone?.prefix ? `${footer?.phone?.prefix}:` : ''}
							<Box
								component='span'
								onClick={() => (window.location.href = 'tel:' + footer?.phone?.phone_number.replace(/[\u2028]/g, ''))}
								sx={{ textDecoration: 'underline', cursor: 'pointer' }}
							>
								{footer?.phone?.phone_number.replace(/[\u2028]/g, '')}
							</Box>
						</StyledTypography>
					</Box>
				</Grid>
				{footer.menu.length > 0 &&
					footer.menu.map((item: any, index: number) => {
						return (
							<Grid
								item
								key={'menu' + index}
								xs={12}
								sm={5.8}
								lg={1.7}
								pb={{ xs: '24px', lg: 0 }}
								textAlign={{ xs: 'center', lg: 'left' }}
							>
								<StyledTypography sx={{ paddingBottom: '24px', fontWeight: 500 }}>{item.title}</StyledTypography>
								{item.menu.map((value: any, index: number) => {
									return (
										<Grid
											item
											key={'link' + index}
										>
											<Link
												to={getRelativeLink(value.menu_link.url)}
												style={{ textDecoration: 'none' }}
											>
												<StyledTypography sx={{ paddingBottom: '16px' }}>{value.menu_link.title}</StyledTypography>
											</Link>
										</Grid>
									);
								})}
							</Grid>
						);
					})}
			</Grid>
			{footer.showAdditionalSection && Object.keys(footer.additional_footer).length > 0 && (
				<>
					<Divider
						component={'hr'}
						sx={{
							margin: '40px auto',
							width: '100%',
							bgcolor: palette.primary.main,
							borderBottomWidth: 1,
						}}
					/>
					<Box
						sx={{
							display: 'flex',
							gap: '40px',
							color: palette.primary.main,
							flexDirection: { xs: 'column', md: 'row' },
						}}
					>
						<Box
							sx={{
								flex: '0 1 32%',
								display: 'flex',
								flexDirection: 'column',
								gap: '20px',
								textAlign: { xs: 'center', md: 'unset' },
							}}
						>
							{footer.additional_footer.title_first_column && (
								<Typography
									sx={{
										position: 'relative',
										margin: 0,
										marginLeft: '10px',
										'&:before': {
											display: { xs: 'none', md: 'block' },
											content: "''",
											inset: 0,
											position: 'absolute',
											width: '2px',
											height: '100%',
											bgcolor: palette.primary.main,
											marginLeft: '-10px',
										},
									}}
								>
									{footer.additional_footer.title_first_column}
								</Typography>
							)}
							{cleanText && (
								<Box
									sx={{
										fontSize: '16px',
										'& p': {
											margin: 0,
										},
									}}
									dangerouslySetInnerHTML={{
										__html: cleanUpHtml(cleanText),
									}}
								></Box>
							)}
						</Box>
						<Box
							sx={{
								flex: '0 1 32%',
								display: 'flex',
								flexDirection: 'column',
								gap: '20px',
								textAlign: { xs: 'center', md: 'unset' },
							}}
						>
							{footer.additional_footer.title_second_column && (
								<Typography
									sx={{
										position: 'relative',
										margin: 0,
										marginLeft: '10px',
										'&:before': {
											display: { xs: 'none', md: 'block' },
											content: "''",
											inset: 0,
											position: 'absolute',
											width: '2px',
											height: '100%',
											bgcolor: palette.primary.main,
											marginLeft: '-10px',
										},
									}}
								>
									{footer.additional_footer.title_second_column}
								</Typography>
							)}
							{footer.additional_footer.additional_address.length > 0 &&
								footer.additional_footer.additional_address.map((it: any, index: number) => {
									return (
										<Box
											key={'additional-address-' + index}
											sx={{
												display: 'flex',
												flexDirection: 'column',
												gap: '5px',
											}}
										>
											<Typography sx={{ fontWeight: 500, margin: 0 }}>{it.first_line_address}</Typography>
											<Typography sx={{ margin: 0 }}>{it.second_line_address}</Typography>
										</Box>
									);
								})}
						</Box>
						<Box
							sx={{
								flex: '0 1 32%',
								display: 'flex',
								flexDirection: 'column',
								gap: '20px',
								textAlign: { xs: 'center', md: 'unset' },
							}}
						>
							{footer.additional_footer.title_third_column && (
								<Typography
									sx={{
										position: 'relative',
										margin: 0,
										marginLeft: '10px',
										'&:before': {
											display: { xs: 'none', md: 'inline' },
											content: "''",
											inset: 0,
											position: 'absolute',
											width: '2px',
											height: '100%',
											bgcolor: palette.primary.main,
											marginLeft: '-10px',
										},
									}}
								>
									{footer.additional_footer.title_third_column}
								</Typography>
							)}
							{footer.additional_footer.subtitle_third_column && (
								<Typography>{footer.additional_footer.subtitle_third_column}</Typography>
							)}
							{footer.additional_footer.third_line_address && footer.additional_footer.third_line_address_second && (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: '5px',
									}}
								>
									<Typography sx={{ fontWeight: 500, margin: 0 }}>
										{footer.additional_footer.third_line_address}
									</Typography>
									<Typography sx={{ margin: 0 }}>{footer.additional_footer.third_line_address_second}</Typography>
								</Box>
							)}
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: '5px',
								}}
							>
								<Typography sx={{ fontWeight: 500, margin: 0 }}>Telefon</Typography>
								<StyledTypography>
									<Box
										component='span'
										onClick={() =>
											(window.location.href = 'tel:' + footer?.phone?.phone_number.replace(/[\u2028]/g, ''))
										}
										sx={{ textDecoration: 'underline', cursor: 'pointer' }}
									>
										{footer?.phone?.phone_number.replace(/[\u2028]/g, '')}
									</Box>
								</StyledTypography>
							</Box>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: '5px',
								}}
							>
								<Typography sx={{ fontWeight: 500, margin: 0 }}>Adres email</Typography>
								<StyledTypography>
									<Box
										component='span'
										onClick={() => (window.location.href = 'mailto:' + footer?.email?.email.replace(/[\u2028]/g, ''))}
										sx={{ textDecoration: 'underline', cursor: 'pointer' }}
									>
										{footer?.email?.email.replace(/[\u2028]/g, '')}{' '}
									</Box>
								</StyledTypography>
							</Box>
						</Box>
					</Box>
				</>
			)}
			<Divider
				component={'hr'}
				sx={{
					margin: '40px auto',
					width: '100%',
					bgcolor: palette.primary.main,
					borderBottomWidth: 1,
				}}
			/>
			<Grid
				component='section'
				container
				item
				xs={12}
				justifyContent={'space-between'}
				alignItems={'center'}
				sx={{
					flexDirection: { xs: 'column', md: 'row' },
					gap: { xs: '30px', md: 0 },
					flexWrap: { xs: 'nowrap', md: 'wrap' },
				}}
			>
				<Grid item>
					<Link
						style={{ textDecoration: 'none' }}
						to={'https://www.expansja.pl'}
					>
						<StyledTypography
							sx={{
								fontWeight: 500,
							}}
						>
							{footer?.copyright}
						</StyledTypography>
					</Link>
				</Grid>
				<Grid
					item
					sx={{
						display: 'flex',
						justifyContent: 'center',
						gap: '80px',
						flex: { xs: 'none', md: '0.85' },
					}}
				>
					<Link
						style={{ textDecoration: 'none' }}
						to={getRelativeLink(footer?.cookies?.link?.url)}
					>
						<StyledTypography
							sx={{
								fontWeight: 500,
							}}
						>
							{footer?.cookies?.title}
						</StyledTypography>
					</Link>
					<Link
						to={getRelativeLink(footer?.copyrightLink?.link?.url)}
						style={{ textDecoration: 'none' }}
					>
						<StyledTypography
							sx={{
								fontWeight: 500,
							}}
						>
							{footer?.copyrightLink?.title}
						</StyledTypography>
					</Link>
				</Grid>
				<Grid item>
					<Box
						component='img'
						src={footer?.logo?.url}
						sx={{ width: { xs: '90%', sm: 'auto' } }}
					></Box>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Footer;
