import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { updateOlympicsSettings } from '../../api/content-api';
import SnackbarContext from '../../store/snackbar-context';
import Loader from '../../ui/Loader';
import AuthContext from '../../store/auth-context';
import { useNavigate } from 'react-router-dom';
import { loginPage } from '../../store/fixed-routing';
import Olympics from '../../model/Olympics';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pl';
import SaveIcon from '@mui/icons-material/Save';
import ReplayIcon from '@mui/icons-material/Replay';
import ConfirmationDialog from './ConfirmationDialog';
import OlympicsContext from '../../store/olympics-context';
import NewOlympicsDialog from './NewOlympicsDialog';

type SettingsRowProps = {
	firstChild: any;
	secondChild?: any;
	thirdChild?: any;
};
const SettingsRow = ({ firstChild, secondChild, thirdChild }: SettingsRowProps) => {
	return (
		<Grid
			item
			container
			gap={'20px'}
			flexWrap={'nowrap'}
			mt={2}
		>
			<Grid
				item
				container
			>
				{firstChild}
			</Grid>
			{secondChild && (
				<Grid
					item
					container
				>
					{secondChild}
				</Grid>
			)}
			{thirdChild && (
				<Grid
					item
					container
				>
					{thirdChild}
				</Grid>
			)}
		</Grid>
	);
};
const SettingsPage = () => {
	const navigate = useNavigate();
	const { setMsg } = useContext(SnackbarContext);
	const { logout, token } = useContext(AuthContext);
	const { olympics, setOlympics } = useContext(OlympicsContext);
	const [openNewOlympicsDialog, setOpenNewOlympicsDialog] = useState(false);
	const [dialogContent, setDialogContent] = useState<string | null>(null);
	const [sendingRequest, setSendingRequest] = useState(false);
	const [okFunction, setOkFunction] = useState<Function | null>(null);

	const setProperty = (obj: any, path: string, value: any): any => {
		const [head, ...rest] = path.split('.');

		return {
			...obj,
			[head]: rest.length ? setProperty(obj[head], rest.join('.'), value) : value,
		};
	};
	const handlePointsChange = (event: any, path: string) => {
		const value = Number(event.target.value);
		if (olympics) {
			setOlympics(setProperty(olympics, path, value));
		}
	};

	const handleValueChange = (path: string, value: any) => {
		if (olympics) {
			setOlympics(setProperty(olympics, path, value));
		}
	};

	const closeDialog = () => {
		setSendingRequest(false);
		setOkFunction(null);
		setDialogContent(null);
	};

	const updateOlympics = () => {
		if (olympics) {
			setSendingRequest(true);
			updateOlympicsSettings(token, olympics)
				.then((response) => {
					if (response.status === 200) {
						setOlympics(Olympics.fromApiResponse(response.data));
						setMsg({ msg: 'Ustawienia zostały zaktualizowane', severity: 'success' });
					} else {
						console.log(response);
						setMsg({ msg: response.data });
					}
					closeDialog();
				})
				.catch((exception) => {
					console.error(exception);
					if (exception.response.status === 401 || exception.response.status === 403) {
						logout();
						navigate(loginPage);
						setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
					} else {
						setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
					}
					closeDialog();
				});
		} else {
			closeDialog();
		}
	};

	const renderSettings = () => {
		return (
			<Grid
				item
				container
				flexWrap={'nowrap'}
				gap={'50px'}
				sx={{
					maxWidth: '100%',
				}}
			>
				<Grid
					item
					container
					flexDirection={'column'}
				>
					<Typography
						mt={5}
						variant={'h4'}
					>
						Ustawienia rejestracji
					</Typography>
					<Divider sx={{ mt: 2, mb: 2 }} />
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale='pl'
					>
						<DateTimePicker
							label='Koniec rejestracji nauczycieli'
							value={olympics?.teachersRegistrationFinishDate}
							onChange={(newValue) => {
								if (olympics) {
									setOlympics({ ...olympics, teachersRegistrationFinishDate: newValue });
								}
							}}
							sx={{
								mt: 3,
								maxWidth: '50%',
								fontSize: '12px',
							}}
						/>
						<DateTimePicker
							label='Koniec rejestracji uczniów'
							value={olympics?.studentsRegistrationFinishDate}
							onChange={(newValue) => {
								if (olympics) {
									setOlympics({ ...olympics, studentsRegistrationFinishDate: newValue });
								}
							}}
							sx={{
								mt: 3,
								maxWidth: '50%',
								fontSize: '12px',
							}}
						/>
						<DateTimePicker
							label='Koniec potwierdzania uczestników'
							value={olympics?.registrationConfirmationFinishDate}
							onChange={(newValue) => {
								if (olympics) {
									setOlympics({ ...olympics, registrationConfirmationFinishDate: newValue });
								}
							}}
							sx={{
								mt: 3,
								maxWidth: '50%',
								fontSize: '12px',
							}}
						/>
					</LocalizationProvider>
					<Typography
						mt={5}
						variant={'h4'}
					>
						Ustawienia etapów
					</Typography>
					<Divider sx={{ mt: 2 }} />
					<Typography mt={5}>Etap I</Typography>
					<Typography
						mt={2}
						variant='body2'
						sx={{ fontStyle: 'italic', fontWeight: 600 }}
					>
						Ręczna kwalifikacja uczniów
					</Typography>
					<Typography mt={5}>Etap IIA</Typography>
					<Typography
						mt={2}
						variant='body2'
						sx={{ fontStyle: 'italic', fontWeight: 600 }}
					>
						Kwalifikacja na podstawie limitu punktów
					</Typography>
					<Typography
						mt={2}
						variant='body2'
						sx={{ fontStyle: 'italic' }}
					>
						Wariant 1
					</Typography>
					<SettingsRow
						firstChild={
							<TextField
								type={'number'}
								label={'Część pisemna nr 1'}
								fullWidth
								value={olympics?.secondStagePoints.partA.v1.part1}
								onChange={(event) => handlePointsChange(event, 'secondStagePoints.partA.v1.part1')}
							/>
						}
						secondChild={
							<TextField
								type={'number'}
								label={'Część pisemna nr 2'}
								fullWidth
								value={olympics?.secondStagePoints.partA.v1.part2}
								onChange={(event) => handlePointsChange(event, 'secondStagePoints.partA.v1.part2')}
							/>
						}
					/>
					<Typography
						mt={2}
						variant='body2'
						sx={{ fontStyle: 'italic' }}
					>
						Wariant 2
					</Typography>
					<SettingsRow
						firstChild={
							<TextField
								type={'number'}
								label={'Część pisemna nr 1'}
								fullWidth
								value={olympics?.secondStagePoints.partA.v2.part1}
								onChange={(event) => handlePointsChange(event, 'secondStagePoints.partA.v2.part1')}
							/>
						}
						secondChild={
							<TextField
								type={'number'}
								label={'Część pisemna nr 2'}
								fullWidth
								value={olympics?.secondStagePoints.partA.v2.part2}
								onChange={(event) => handlePointsChange(event, 'secondStagePoints.partA.v2.part2')}
							/>
						}
					/>
					<Typography
						mt={2}
						variant='body2'
						sx={{ fontStyle: 'italic' }}
					>
						Łączny limit z części pisemnej
					</Typography>
					<SettingsRow
						firstChild={
							<TextField
								type={'number'}
								label={'Łączny limit z części 1 i 2'}
								fullWidth
								value={olympics?.secondStagePoints.partA.total}
								onChange={(event) => handlePointsChange(event, 'secondStagePoints.partA.total')}
							/>
						}
					/>
					<Typography mt={5}>Etap IIB</Typography>
					<Typography
						mt={2}
						variant='body2'
						sx={{ fontStyle: 'italic', fontWeight: 600 }}
					>
						Kwalifikacja na podstawie decyzji administratora
					</Typography>
					<SettingsRow
						firstChild={
							<TextField
								type={'number'}
								label={'Część ustna nr 1'}
								fullWidth
								value={olympics?.secondStagePoints.partB.part1}
								onChange={(event) => handlePointsChange(event, 'secondStagePoints.partB.part1')}
							/>
						}
						secondChild={
							<TextField
								type={'number'}
								label={'Część ustna nr 2'}
								fullWidth
								value={olympics?.secondStagePoints.partB.part2}
								onChange={(event) => handlePointsChange(event, 'secondStagePoints.partB.part2')}
							/>
						}
						thirdChild={
							<TextField
								label={'Część ustna nr 3'}
								fullWidth
								value={olympics?.secondStagePoints.partB.part3}
								onChange={(event) => handlePointsChange(event, 'secondStagePoints.partB.part3')}
							/>
						}
					/>
					<Typography
						mt={2}
						variant='body2'
						sx={{ fontStyle: 'italic' }}
					>
						Łączny limit punktów z części pisemnej i ustnej
					</Typography>
					<SettingsRow
						firstChild={
							<TextField
								type={'number'}
								label={'Łączny limit z części IIA i IIB'}
								fullWidth
								value={olympics?.secondStagePoints.partB.total}
								onChange={(event) => handlePointsChange(event, 'secondStagePoints.partB.total')}
							/>
						}
					/>
					<Typography
						mt={2}
						variant='body2'
						sx={{ fontStyle: 'italic' }}
					>
						Limit liczby uczniów
					</Typography>
					<SettingsRow
						firstChild={
							<TextField
								type={'number'}
								label={'Limit liczby uczniów'}
								fullWidth
								value={olympics?.secondStagePoints.partB.totalStudentsLimit}
								onChange={(event) => handlePointsChange(event, 'secondStagePoints.partB.totalStudentsLimit')}
							/>
						}
					/>
					<SettingsRow
						firstChild={
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale='pl'
							>
								<DateTimePicker
									label='Koniec wprowadzania wyników przez sekretarzy'
									value={olympics?.secondStagePoints?.partB?.stageClosedDate}
									onChange={(newValue) => {
										if (olympics) {
											handleValueChange('secondStagePoints.partB.stageClosedDate', newValue);
										}
									}}
									sx={{
										mt: 3,
										width: '100%',
										fontSize: '12px',
									}}
								/>
							</LocalizationProvider>
						}
					/>
					<Typography mt={5}>Etap IIIA</Typography>
					<SettingsRow
						firstChild={
							<TextField
								type={'number'}
								label={'Część pisemna'}
								fullWidth
								value={olympics?.thirdStagePoints.partA.points}
								onChange={(event) => handlePointsChange(event, 'thirdStagePoints.partA.points')}
							/>
						}
					/>
					<Typography
						mt={2}
						variant='body2'
						sx={{ fontStyle: 'italic', fontWeight: 600 }}
					>
						Finalista
					</Typography>
					<SettingsRow
						firstChild={
							<TextField
								type={'number'}
								label={'Limit punktów dla Finalisty'}
								fullWidth
								value={olympics?.thirdStagePoints.partA.finalistPoints}
								onChange={(event) => handlePointsChange(event, 'thirdStagePoints.partA.finalistPoints')}
							/>
						}
					/>
					<Typography mt={5}>Etap IIIB</Typography>
					<Typography
						mt={2}
						variant='body2'
						sx={{ fontStyle: 'italic', fontWeight: 600 }}
					>
						Laureaci
					</Typography>
					<SettingsRow
						firstChild={
							<TextField
								type={'number'}
								label={'Laureat III stopnia'}
								fullWidth
								value={olympics?.thirdStagePoints.partB.laureate3}
								onChange={(event) => handlePointsChange(event, 'thirdStagePoints.partB.laureate3')}
							/>
						}
						secondChild={
							<TextField
								type={'number'}
								label={'Laureat II stopnia'}
								fullWidth
								value={olympics?.thirdStagePoints.partB.laureate2}
								onChange={(event) => handlePointsChange(event, 'thirdStagePoints.partB.laureate2')}
							/>
						}
						thirdChild={
							<TextField
								type={'number'}
								label={'Laureat I stopnia'}
								fullWidth
								value={olympics?.thirdStagePoints.partB.laureate1}
								onChange={(event) => handlePointsChange(event, 'thirdStagePoints.partB.laureate1')}
							/>
						}
					/>
				</Grid>
				<Grid
					item
					container
					flexDirection={'column'}
				>
					<Typography
						mt={5}
						variant={'h4'}
					>
						Opcje
					</Typography>
					<Divider sx={{ mt: 2, mb: 2 }} />
					<Button
						startIcon={<SaveIcon />}
						variant={'contained'}
						onClick={() => {
							setDialogContent('Czy napewno chcesz zmienić ustawienia olimpiady?');
							setOkFunction(() => updateOlympics);
						}}
					>
						Zapisz zmiany
					</Button>
					<Button
						startIcon={<ReplayIcon />}
						variant={'contained'}
						color={'error'}
						onClick={() => setOpenNewOlympicsDialog(true)}
						sx={{
							mt: '100px',
							mb: 2,
						}}
					>
						Nowa olimpiada
					</Button>
					<Typography>- tworzy nową edycję olimpiady</Typography>
					<Typography>- usuwa dane uczniów i nauczycieli</Typography>
				</Grid>
			</Grid>
		);
	};

	return (
		<>
			{!olympics ? <Loader /> : renderSettings()}
			<NewOlympicsDialog
				open={openNewOlympicsDialog}
				close={() => setOpenNewOlympicsDialog(false)}
				onSuccess={() => {}}
			/>
			<ConfirmationDialog
				close={() => closeDialog()}
				inProgress={sendingRequest}
				text={dialogContent}
				onSuccess={() => okFunction && okFunction()}
			/>
		</>
	);
};

export default SettingsPage;
