import { ReactNode, useContext } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import { Box } from '@mui/material';
import SnackbarContext from '../store/snackbar-context';
import ContentContext from '../store/content-context';
import Breadcrumbs from '../components/Header/Breadcrumbs';
import CustomSnackbar from '../components/UI/CustomSnackbar';

const MainLayout = ({ children }: { children: ReactNode }) => {
	const { settings, menu, pages } = useContext(ContentContext);

	return (
		<>
			<Header menu={menu} />
			<Breadcrumbs pages={pages} />
			<Box component={'main'}>{children}</Box>
			<CustomSnackbar />
			<Footer footer={settings} />
		</>
	);
};

export default MainLayout;
