import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import AuthContext from '../../store/auth-context';
import SnackbarContext from '../../store/snackbar-context';
import { useNavigate } from 'react-router-dom';
import { StageOption } from './StagesTable';
import { publishStage } from '../../api/content-api';
import Olympics from '../../model/Olympics';
import { loginPage } from '../../store/fixed-routing';
import OlympicsContext from '../../store/olympics-context';

type Props = {
	stage: StageOption;
	close: () => void;
	onSuccess: () => void;
};
const ChangePublishedStageDialog = ({ stage, close, onSuccess }: Props) => {
	const { palette } = useTheme();
	const { setOlympics } = useContext(OlympicsContext);
	const { token, logout } = useContext(AuthContext);
	const navigate = useNavigate();
	const { setMsg } = useContext(SnackbarContext);
	const [sendingRequest, setSendingRequest] = useState(false);

	const handleApprove = () => {
		setSendingRequest(true);
		publishStage(token, stage.key)
			.then((response) => {
				if (response.status === 200) {
					setOlympics(Olympics.fromApiResponse(response.data));
					onSuccess();
					setMsg({ msg: `${stage.title} został opublikowany`, severity: 'success' });
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
				setSendingRequest(false);
			})
			.catch((exception) => {
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
				} else {
					setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
				}
				setSendingRequest(false);
				close();
			});
	};

	return (
		<>
			<Dialog
				open={stage.key > 0}
				onClose={() => close()}
			>
				<DialogTitle sx={{ fontSize: '24px' }}>
					Czy napewno chcesz opublikować wyniki dla etapu {stage.title}?
				</DialogTitle>
				<DialogActions>
					<Button
						sx={{
							color: palette.secondary.main,
						}}
						onClick={() => close()}
					>
						Anuluj
					</Button>
					<Button
						sx={{
							color: palette.success.main,
						}}
						onClick={() => handleApprove()}
					>
						{sendingRequest ? <CircularProgress /> : 'Zapisz'}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ChangePublishedStageDialog;
