class Specialization {
	id: number;
	name: string;

	private constructor(id: number, name: string) {
		this.id = id;
		this.name = name;
	}

	public static fromApiResponse(data: any) {
		return new Specialization(data.id ? data.id : 0, data.name ? data.name : '');
	}
}

export default Specialization;
