import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	useTheme,
} from '@mui/material';
import { addSchool, getAllDistricts } from '../../api/content-api';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../store/auth-context';
import SnackbarContext from '../../store/snackbar-context';
import { useNavigate } from 'react-router-dom';
import District from '../../model/District';
import { loginPage } from '../../store/fixed-routing';
import ManagedFormField, { ManagedFormFieldProps } from '../../ui/Form/ManagedFormField';
import ManagedFormContext from '../../ui/Form/MangedForm';
import ManagedFormSelectField from '../../ui/Form/ManagedFormSelectField';

type TextFormFieldProps = ManagedFormFieldProps & {
	fullWidth?: boolean;
};
export const TextFormField = (props: TextFormFieldProps) => {
	return (
		<Grid
			item
			container
			sx={{
				width: props.fullWidth ? '100%' : `calc(50% - 10px)`,
				my: '10px',
			}}
		>
			<ManagedFormField
				fullWidth
				{...props}
			/>
		</Grid>
	);
};

type Props = {
	open: boolean;
	onClose: () => void;
};

const NewSchoolDialog = ({ open, onClose }: Props) => {
	const { palette } = useTheme();
	const { token, logout } = useContext(AuthContext);
	const navigate = useNavigate();
	const { setMsg } = useContext(SnackbarContext);
	const { reset, validate, getFormData } = useContext(ManagedFormContext);
	const [sendingRequest, setSendingRequest] = useState(false);
	const [districts, setDistricts] = useState<District[]>([]);

	const handleApprove = () => {
		const validationResult = validate();
		if (validationResult) {
			setMsg({ msg: validationResult, severity: 'warning' });
			return;
		}
		setSendingRequest(true);
		if (sendingRequest) {
			return;
		}
		const formData = getFormData();
		const data = {
			...formData,
			districtId: formData.district,
		};
		delete data.district;
		addSchool(token, data)
			.then((response) => {
				if (response.status === 200) {
					setMsg({ msg: 'Dane zostały zaktualizowane', severity: 'success' });
					window.location.reload();
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
				setSendingRequest(false);
			})
			.catch((exception) => {
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
				} else if (exception?.response?.data?.message) {
					setMsg({ msg: exception?.response?.data?.message });
				}
				setSendingRequest(false);
			});
	};

	useEffect(() => {
		getAllDistricts()
			.then((response) => {
				if (response.status === 200) {
					setDistricts(response.data.map((d: any) => District.fromApiResponse(d)));
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
			})
			.catch((exception) => {
				console.error(exception);
				setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
			});
	}, []);

	return (
		<>
			<Dialog
				open={open}
				onClose={() => {
					reset();
					onClose();
				}}
			>
				<DialogTitle sx={{ fontSize: '24px' }}>Dodaj nową szkołę</DialogTitle>
				<DialogContent>
					<Grid
						item
						container
						justifyContent={'space-between'}
					>
						<TextFormField
							type={'number'}
							min={99}
							label={'RSPO'}
							accessor={'rspo'}
						/>
						<TextFormField
							fullWidth
							label={'Nazwa szkoły'}
							accessor={'name'}
						/>
						<ManagedFormSelectField
							label={'Okręg'}
							accessor={'district'}
							options={districts.map((d) => ({ label: d.name, value: d.id }))}
							formControlProps={{
								sx: {
									width: '100%',
									my: '10px',
								},
							}}
							menuItemProps={{
								sx: { fontSize: '16px' },
							}}
						/>
						<TextFormField
							fullWidth
							label={'Ulica'}
							accessor={'street'}
						/>
						<TextFormField
							label={'Numer budynku'}
							accessor={'building'}
						/>
						<TextFormField
							label={'Numer lokalu'}
							accessor={'apartment'}
							optional={true}
						/>
						<TextFormField
							type={'zipCode'}
							label={'Kod pocztowy'}
							errorMessage={'Kod pocztowy powinnien mieć format 12-345'}
							accessor={'zipCode'}
						/>
						<TextFormField
							label={'Miasto'}
							accessor={'city'}
						/>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						sx={{
							color: palette.secondary.main,
						}}
						onClick={() => {
							setTimeout(() => reset(), 500);
							onClose();
						}}
					>
						Anuluj
					</Button>
					<Button
						sx={{
							color: palette.success.main,
						}}
						onClick={() => handleApprove()}
					>
						{sendingRequest ? <CircularProgress /> : 'Zapisz'}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewSchoolDialog;
