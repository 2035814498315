import { useTheme } from '@mui/material';

const AddIcon = () => {
	const { palette } = useTheme();

	return (
		<svg
			width='24'
			height='26'
			viewBox='0 0 24 26'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M0 14.9796H24V11.0208H0V14.9796ZM9.89691 0.876465V25.1239H14.1031V0.876465H9.89691Z'
				fill={palette.secondary.main}
			/>
		</svg>
	);
};

export default AddIcon;
