import Block from '../model/Block';
import NewsBlock from './NewsBlock';
import FullWidthGallery from './FullWidthGallery';
import ImageAndTextWithButtons from './ImageAndTextWithButtons';
import FullWidthText from './FullWidthText';
import HeaderWithLeaf from './HeaderWithLeaf';
import TextAndQuoteWithPhoto from './TextAndQuoteWithPhoto';
import ScheduleWithSwitchContent from './ScheduleWithSwitchContent/ScheduleWithSwitchContent';
import ContactSection from './ContactSection';
import DropDownListSection from './DropDownListSection';
import CentredText from './CentredText';
import Title from './Title';
import ImgWithBg from './ImgWithBg';
import ArticleNewsList from './ArticleNewsList';
import ExtendedContactInfo from './ExtendedContactInfo';
import PostContent from './PostContent';

const getBlocks = (blocks: Block[]) => {
	return (
		blocks &&
		blocks.map((block, index) => {
			switch (block.component) {
				case 'text_and_quote_with_photo':
					return (
						<TextAndQuoteWithPhoto
							key={index}
							content={block.content}
						/>
					);
				case 'news_block':
					return (
						<NewsBlock
							key={index}
							content={block.content}
						/>
					);
				case 'full_width_gallery':
					return (
						<FullWidthGallery
							key={index}
							content={block.content}
						/>
					);
				case 'schedule_with_switch_content':
					return (
						<ScheduleWithSwitchContent
							key={index}
							content={block.content}
						/>
					);
				case 'image_and_text_with_buttons':
					return (
						<ImageAndTextWithButtons
							key={index}
							content={block.content}
						/>
					);
				case 'full_width_text':
					return (
						<FullWidthText
							key={index}
							content={block.content}
						/>
					);
				case 'header_with_leaf':
					return (
						<HeaderWithLeaf
							key={index}
							content={block.content}
						/>
					);
				case 'dropdown_list':
					return (
						<DropDownListSection
							key={index}
							content={block.content}
						/>
					);
				case 'contact_section':
					return (
						<ContactSection
							key={index}
							content={block.content}
						/>
					);
				case 'title':
					return (
						<Title
							key={index}
							content={block.content}
						/>
					);
				case 'centered_desc':
					return (
						<CentredText
							key={index}
							content={block.content}
						/>
					);
				case 'photo_with_bg':
					return (
						<ImgWithBg
							key={index}
							content={block.content}
						/>
					);
				case 'articles_news_list':
					return (
						<ArticleNewsList
							key={index}
							content={block.content}
						/>
					);
				case 'extended_contact_info':
					return (
						<ExtendedContactInfo
							key={index}
							content={block.content}
						/>
					);
				case 'post_content':
					return (
						<PostContent
							key={index}
							content={block.content}
						/>
					);
				default:
					return;
			}
		})
	);
};

export default getBlocks;
