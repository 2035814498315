import { Box, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import FullHeader from './FullHeader';
import MinHeader from './MinHeader';

const StickyHeader = ({ menu }: any) => {
	const [show, setShow] = useState(false);
	const matches = useMediaQuery('(min-width: 1300px)');

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleScroll = () => {
		if (typeof window !== 'undefined') {
			if (window.pageYOffset > 100) {
				setShow(true);
			} else {
				setShow(false);
			}
		}
	};

	return (
		<Box
			sx={{
				position: 'fixed',
				top: show ? '0' : '-200px',
				left: 0,
				width: '100%',
				zIndex: 5000,
				transition: 'all .5s',
			}}
		>
			{matches ? (
				<FullHeader
					menu={menu}
					small
				/>
			) : (
				<MinHeader menu={menu} />
			)}
		</Box>
	);
};

export default StickyHeader;
