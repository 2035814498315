import Address from './Address';

class Settings {
	quote: string;
	company_logo: any;
	address: Address;
	email: any;
	phone: any;
	menu: any;
	copyright: string;
	copyrightLink: any;
	cookies: any;
	logo: any;
	additional_footer: any;
	showAdditionalSection: boolean;

	private constructor(
		quote: string,
		company_logo: any,
		address: Address,
		email: any,
		phone: any,
		menu: any,
		copyright: string,
		copyrightLink: any,
		cookies: any,
		logo: any,
		additional_footer: any,
		show_additional_section: boolean,
	) {
		this.quote = quote;
		this.company_logo = company_logo;
		this.address = address;
		this.email = email;
		this.phone = phone;
		this.menu = menu;
		this.copyright = copyright;
		this.copyrightLink = copyrightLink;
		this.cookies = cookies;
		this.logo = logo;
		this.additional_footer = additional_footer;
		this.showAdditionalSection = show_additional_section;
	}

	public static fromApiResponse({ acf }: any) {
		return new Settings(
			acf.footer_quote,
			acf.footer_company_logo,
			Address.fromApiResponse(acf.footer_address),
			acf.footer_email,
			acf.footer_phone,
			acf.footer_menu,
			acf.copyright,
			acf.copyright_link,
			acf.cookies,
			acf.footer_logo,
			acf.additional_footer,
			acf.show_additional_section,
		);
	}
}

export default Settings;
