import { WPImage } from './WPImage';
import { getRelativeLink } from '../utils/string-tools';
import Page, { Breadcrumb } from './Page';
import Block from './Block';

class Post extends Page {
	constructor(link: string, title: string, breadcrumbs: Breadcrumb[], blocks: Block[]) {
		super(link, title, breadcrumbs, blocks);
	}

	public static fromApiResponse(data: any) {
		const pages = [] as Page[];
		data.forEach((d: any) => {
			const blocks = [
				new Block('post_content', {
					post_title: d.post_title,
					authors: d.authors,
					parent: d.breadcrumbs[d.breadcrumbs.length - 1],
					...d.acf,
				}),
			];
			pages.push(new Page(getRelativeLink(d.link), d.post_title, d.breadcrumbs, blocks));
		});
		return pages;
	}
}

export default Post;
