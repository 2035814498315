import { Box, Typography, keyframes, useTheme } from '@mui/material';
import BlockWrapper from '../components/BlockWrapper';
import { useContext } from 'react';
import ContentContext from '../store/content-context';

type Props = {
	content: any;
};

const ContactSection = ({ content }: Props) => {
	const { breakpoints, typography, palette } = useTheme();
	const { settings } = useContext(ContentContext);

	const slide = keyframes`
    0% {
       transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  `;

	return (
		<BlockWrapper
			justify='space-between'
			lgSize={0}
			withPadding={false}
			sx={{
				bgcolor: content.section_background_color && content.section_background_color,
				mb: '80px',
				position: 'relative',
			}}
		>
			<Box
				position={'absolute'}
				sx={{
					width: '100%',
					height: '100px',
					bgcolor: content.section_background_color && content.section_background_color,
					top: '-80px',
					zIndex: -1,
				}}
			></Box>
			<Box
				overflow={'hidden'}
				component={'section'}
				sx={{
					height: '70px',
					display: 'flex',
					alignItems: 'center',
					position: 'relative',
					pt: '25px',
					pb: '74px',
					width: '100%',
					[breakpoints.down('md')]: {
						pb: '56px',
					},
				}}
			>
				<Box
					display={'flex'}
					gap={'600px'}
					alignItems={'center'}
					sx={{
						width: '100%',
						animation: `${slide} 30s linear infinite`,
					}}
				>
					<Typography
						component={'span'}
						variant={'h1'}
						sx={{
							display: 'inline-block',
							color: content.section_font_color && content.section_font_color,
							fontWeight: 500,
							whiteSpace: 'nowrap',
						}}
					>
						{content.animated_text && content.animated_text}
					</Typography>
				</Box>
			</Box>
			<Box
				component={'section'}
				display={'flex'}
				width={'100%'}
				sx={{
					[breakpoints.down('sm')]: {
						flexDirection: 'column',
					},
				}}
			>
				<Box
					display={'flex'}
					flexDirection={'column'}
					flexBasis={'50%'}
					sx={{
						[breakpoints.down('sm')]: {
							flexBasis: '100%',
						},
					}}
				>
					<Box
						height={'50%'}
						bgcolor={content.contact_background_color ? content.contact_background_color : palette.secondary.main}
						p={'64px 20px 64px 40px'}
					>
						{settings.email && (
							<Typography
								color={content.contact_font_color && content.contact_font_color}
								textAlign={'center'}
								fontSize={typography.h3.fontSize}
								fontWeight={'500'}
							>
								{settings.email.prefix && settings.email.prefix}
								{': '}
								<Typography
									component={'span'}
									fontWeight={'500'}
									fontSize={typography.h3.fontSize}
									sx={{
										textDecoration: 'underline',
										cursor: 'pointer',
									}}
									onClick={() => {
										if (settings.email.email) window.location.href = 'mailto:' + settings.email.email;
									}}
								>
									{settings.email.email && settings.email.email}
								</Typography>
							</Typography>
						)}
						{settings.phone && (
							<Typography
								color={content.contact_font_color && content.contact_font_color}
								textAlign={'center'}
								fontSize={typography.h3.fontSize}
								fontWeight={'500'}
							>
								{settings.phone.prefix && settings.phone.prefix}
								{': '}
								<Typography
									component={'span'}
									fontWeight={'500'}
									fontSize={typography.h3.fontSize}
									sx={{
										textDecoration: 'underline',
										cursor: 'pointer',
									}}
									onClick={() => {
										if (settings.phone.phone_number) window.location.href = 'tel:' + settings.phone.phone_number;
									}}
								>
									{settings.phone.phone_number && settings.phone.phone_number}
								</Typography>
							</Typography>
						)}
					</Box>
					<Box
						height={'50%'}
						bgcolor={content.address_background_color && content.address_background_color}
						p={'41px 20px 41px 40px'}
						sx={{
							[breakpoints.down('lg')]: {
								pb: '80px',
							},
							[breakpoints.down('md')]: {
								pb: '41px',
							},
						}}
					>
						{settings.address && (
							<Typography
								color={content.address_font_color && content.address_font_color}
								textAlign={'center'}
								fontSize={typography.h3.fontSize}
								fontWeight={'500'}
							>
								{settings.address.firstLine}
								<Box component={'br'} />
								{`${settings.address.street} ${settings.address.number}, ${settings.address.zipCode} ${settings.address.city} ${settings.address.place}, ${settings.address.floor}, ${settings.address.room}`}
							</Typography>
						)}
					</Box>
				</Box>
				<Box
					flexBasis={'50%'}
					display={'flex'}
					justifyContent={'center'}
					alignItems={'flex-end'}
					sx={{
						[breakpoints.down('sm')]: {
							pt: '40px',
						},
					}}
				>
					<Box
						component={'img'}
						src={content.image && content.image.url}
						alt={content.image && content.image.alt}
						maxHeight={'30vh'}
						minHeight={'300px'}
						sx={{
							[breakpoints.down('md')]: {
								maxHeight: '20vh',
								minHeight: '200px',
							},
						}}
					/>
				</Box>
			</Box>
		</BlockWrapper>
	);
};

export default ContactSection;
