import Student from '../../../model/Student';
import { Checkbox, TextField, Typography } from '@mui/material';
import CustomTable from '../CustomTable';
import { useContext, useEffect, useState } from 'react';
import { getThirdAStageStudents, updateThirdAStageResult } from '../../../api/content-api';
import { loginPage } from '../../../store/fixed-routing';
import AuthContext from '../../../store/auth-context';
import SnackbarContext from '../../../store/snackbar-context';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../ui/Loader';
import ActionButtons from './ActionButtons';
import Result from '../../../model/Result';
import { DEFAULT_API_ERROR, DEFAULT_AUTH_ERROR } from '../../../utils/auth-tools';
import OlympicsContext from '../../../store/olympics-context';
import { filter } from '../StagesTable';

const ThirdAStageTable = () => {
	const [allStudents, setAllStudents] = useState([]);
	const [displayedStudents, setDisplayedStudents] = useState<Student[]>([]);
	const [editedStudent, setEditedStudent] = useState<Student>();
	const [isLoading, setIsLoading] = useState(true);
	const [isUpdating, setIsUpdating] = useState(false);
	const { logout, token, isAdmin } = useContext(AuthContext);
	const { refresh, districtFilterId } = useContext(OlympicsContext);
	const { setMsg } = useContext(SnackbarContext);
	const navigate = useNavigate();

	useEffect(() => {
		setDisplayedStudents(filter(allStudents, districtFilterId));
	}, [districtFilterId]);

	const downloadData = () => {
		getThirdAStageStudents(token)
			.then((response) => {
				if (response.status === 200) {
					const students = response.data.map((d: any) => Student.fromApiResponse(d));
					setAllStudents(students);
					setDisplayedStudents(filter(students, districtFilterId));
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
				setIsLoading(false);
			})
			.catch((exception) => {
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
				} else {
					setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
				}
				setIsLoading(false);
			});
	};

	const handleSave = () => {
		setIsUpdating(true);
		const data = {
			id: editedStudent?.id,
			thirdAStageTopic: editedStudent?.result.thirdAStageTopic,
			thirdAStagePoints: editedStudent?.result.thirdAStagePoints,
		};
		updateThirdAStageResult(token, data)
			.then((response) => {
				setIsUpdating(false);
				if (response.status === 200) {
					const students = response.data.map((d: any) => Student.fromApiResponse(d));
					setAllStudents(students);
					setDisplayedStudents(filter(students, districtFilterId));
					setEditedStudent(undefined);
					setMsg({ msg: 'Dane ucznia zostały zaktualizowane', severity: 'success' });
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
				setIsLoading(false);
			})
			.catch((exception) => {
				setIsUpdating(false);
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: DEFAULT_AUTH_ERROR });
				} else {
					const msg = exception?.response?.data?.message ? exception?.response?.data?.message : DEFAULT_API_ERROR;
					setMsg({ msg });
				}
				setIsLoading(false);
			});
	};

	useEffect(() => {
		refresh();
		downloadData();
	}, []);

	const handlePointsChange = (event: any, accessor: keyof Result) => {
		if (editedStudent) {
			const result = { ...editedStudent.result };
			// @ts-ignore
			result[accessor] = Number(event.target.value);
			setEditedStudent({
				...editedStudent,
				result: result,
			});
		}
	};

	const handleTextChange = (event: any, accessor: keyof Result) => {
		if (editedStudent) {
			const result = { ...editedStudent.result };
			// @ts-ignore
			result[accessor] = event?.target?.value ? event.target.value.toUpperCase() : '';
			setEditedStudent({
				...editedStudent,
				result: result,
			});
		}
	};

	const renderPointsField = (i: Student, accessor: keyof Result) => {
		return editedStudent?.id === i.id ? (
			<TextField
				variant={'standard'}
				type={'number'}
				value={editedStudent.result[accessor]}
				sx={{
					width: '100%',
					height: '100%',
					justifyContent: 'end',
					'& input': {
						pl: '7%',
						minHeight: '100%',
						margin: 0,
						fontSize: '16px',
						textAlign: 'center',
					},
				}}
				onChange={(event) => handlePointsChange(event, accessor)}
			/>
		) : (
			<Typography
				variant='caption'
				fontWeight='medium'
			>
				{i.result[accessor]}
			</Typography>
		);
	};

	const renderEditableField = (i: Student, accessor: keyof Result) => {
		return editedStudent?.id === i.id ? (
			<TextField
				variant={'standard'}
				value={editedStudent.result[accessor]}
				inputProps={{ maxLength: 2 }}
				sx={{
					width: '100%',
					height: '100%',
					justifyContent: 'end',
					'& input': {
						pl: '7%',
						minHeight: '100%',
						margin: 0,
						fontSize: '16px',
						textAlign: 'center',
					},
				}}
				onChange={(event) => handleTextChange(event, accessor)}
			/>
		) : (
			<Typography
				variant='caption'
				fontWeight='medium'
			>
				{i.result[accessor]}
			</Typography>
		);
	};

	const data = (input: any) => {
		return {
			columns: [
				{ header: 'Lp.', accessor: 'index', align: 'left', width: '60px' },
				{ header: 'Tytuł', accessor: 'personalTitle', align: 'left', width: '100px' },
				{ header: 'Imię', accessor: 'firstName', align: 'left' },
				{ header: 'Nazwisko', accessor: 'lastName', align: 'left' },
				{ header: 'Szkoła', accessor: 'school', align: 'left' },
				{ header: 'Klasa', accessor: 'schoolClass', width: '100px', align: 'center' },
				{ header: 'Specjalizacja', accessor: 'specialization', width: '100px', align: 'center' },
				{ header: 'Temat części ustnej', accessor: 'oralTopic', width: '100px', align: 'left'},
				{ header: 'Temat z językoznawstwa', accessor: 'linguisticTopic', width: '100px', align: 'left'},
				{ header: 'Temat części pisemnej', accessor: 'subject', width: '100px', align: 'center', editable: isAdmin },
				{ header: 'Punkty', accessor: 'points', width: '100px', align: 'center', editable: isAdmin },
				{ header: 'Zakwalifikowany', accessor: 'qualified', width: '150px', align: 'center' },
				{ header: 'Tytuł', accessor: 'title', width: '150px', align: 'center' },
				{ header: '', accessor: 'action', align: 'center', width: '100px', nosort: true, editable: isAdmin },
			],

			rows: input
				? input.map((i: Student, index: number) => {
						return {
							index: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{index + 1}
								</Typography>
							),
							personalTitle: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.personalTitle}
								</Typography>
							),
							firstName: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.firstName}
								</Typography>
							),
							lastName: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.lastName}
								</Typography>
							),
							school: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.school.name}, {i.school.city}
								</Typography>
							),
							schoolClass: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.schoolClass}
								</Typography>
							),
							specialization: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.specialization.name}
								</Typography>
							),
							oralTopic: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.topic.topic}
								</Typography>
							),
							linguisticTopic: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.topic.linguisticTopic}
								</Typography>
							),
							subject: renderEditableField(i, 'thirdAStageTopic'),
							points: renderPointsField(i, 'thirdAStagePoints'),
							title: (
								<Typography
									variant='caption'
									fontWeight='medium'
								>
									{i.result.finalTitle}
								</Typography>
							),
							qualified: (
								<Checkbox
									checked={i.result.thirdAStageQualified}
									disabled={true}
								/>
							),
							action: (
								<ActionButtons
									student={i}
									disabled={!isAdmin}
									editedStudent={editedStudent}
									inProgress={isUpdating}
									handleSave={handleSave}
									handleClose={() => setEditedStudent(undefined)}
									handleEdit={() => setEditedStudent(i)}
								/>
							),
						};
					})
				: [],
		};
	};
	return <>{isLoading ? <Loader /> : <CustomTable data={data(displayedStudents)} />}</>;
};

export default ThirdAStageTable;
