import { getRelativeLink } from '../utils/string-tools';

export class MenuItem {
	id: string;
	title: string;
	url: string;
	subMenu: MenuItem[] | undefined;

	constructor(id: string, title: string, url: string, subMenu?: MenuItem[] | undefined) {
		this.id = id;
		this.title = title;
		this.url = url;
		this.subMenu = subMenu;
	}
}

export class Logo {
	url: string;
	alt: string;

	constructor(url: string, alt: string) {
		this.url = url;
		this.alt = alt;
	}

	public static fromApiResponse(data: any) {
		return new Logo(data.url, data.alt);
	}
}

class Menu {
	menuItems: MenuItem[];
	logo: Logo;
	option_title: string;
	option_link: string;
	language: string;
	login: string;
	signIn: string;

	constructor(
		menuItems: MenuItem[],
		logo: Logo,
		option_title: string,
		option_link: string,
		language: string,
		login: string,
		signIn: string,
	) {
		this.menuItems = menuItems;
		this.logo = logo;
		this.option_title = option_title;
		this.option_link = option_link;
		this.language = language;
		this.login = login;
		this.signIn = signIn;
	}

	private static createSubMenu(id: number, data: any) {
		return data
			.filter(({ menu }: any) => menu.menu_item_parent == id)
			.sort((a: any, b: any) => a.menu.menu_order - b.menu.menu_order)
			.map(
				(item: any) =>
					new MenuItem(
						item.menu.ID,
						item.menu.title,
						getRelativeLink(item.menu.url),
						this.createSubMenu(item.menu.ID, data),
					),
			);
	}

	public static fromApiResponse(data: any) {
		const menu = data[0]?.menu
			.filter((item: any) => item.menu.menu_item_parent == 0)
			.sort((a: any, b: any) => a.menu.menu_order - b.menu.menu_order)
			.map((mainItem: any) => {
				return new MenuItem(
					mainItem.menu.ID,
					mainItem.menu.title,
					getRelativeLink(mainItem.menu.url),
					this.createSubMenu(mainItem.menu.ID, data[0].menu),
				);
			});

		const company_logo = data[0]?.company_logo && Logo.fromApiResponse(data[0]?.company_logo);
		const option_title = data[0]?.option_title;
		const option_link = data[0]?.option_link;
		const language = data[0]?.language;
		const login = data[0]?.login;
		const signIn = data[0]?.signIn;

		return new Menu(menu, company_logo, option_title, option_link, language, login, signIn);
	}
}

export default Menu;
