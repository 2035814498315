class Teachers {
	id: string;
	olympicsID: string;
	districtID: string;
	created: string;
	updated: string;
	title: string;
	degree: string;
	affiliation: string;
	name: string;
	surname: string;
	email: string;
	phone: string;
	street: string;
	building: string;
	apartment: string;
	zipCode: string;
	city: string;

	constructor(
		id: string,
		olympicsID: string,
		districtID: string,
		created: string,
		updated: string,
		title: string,
		degree: string,
		affiliation: string,
		name: string,
		surname: string,
		email: string,
		phone: string,
		street: string,
		building: string,
		apartment: string,
		zipCode: string,
		city: string,
	) {
		this.id = id;
		this.olympicsID = olympicsID;
		this.districtID = districtID;
		this.created = created;
		this.updated = updated;
		this.title = title;
		this.degree = degree;
		this.affiliation = affiliation;
		this.name = name;
		this.surname = surname;
		this.email = email;
		this.phone = phone;
		this.street = street;
		this.building = building;
		this.apartment = apartment;
		this.zipCode = zipCode;
		this.city = city;
	}

	public static fromApiResponse(responses: any) {
		const teachers = [] as Teachers[];
		responses.map((response: any) => {
			response &&
				teachers.push(
					new Teachers(
						response.id,
						response.olympics_id,
						response.district_ID,
						response.created_at,
						response.updated_at,
						response.personal_title,
						response.academic_degree,
						response.affiliation,
						response.first_name,
						response.last_name,
						response.email,
						response.phone,
						response.street,
						response.building,
						response.apartment,
						response.zip_code,
						response.city,
					),
				);
		});
		return teachers;
	}
}

export default Teachers;
