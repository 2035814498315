import { Box, Grid, Link, Typography, useTheme } from '@mui/material';
import PreviousPageArrow from '../../components/UI/PreviousPageArrow';
import NextPageArrow from '../../components/UI/NextPageArrow';
import { cleanUpHtml } from '../../utils/string-tools';
import { getLineHighlight } from '../../styles/theme';

type Props = {
	content: any;
	previous: string | null;
	next: string | null;
	onStepChange: (step: number) => void;
};
const RightPane = ({ content, previous, next, onStepChange }: Props) => {
	const { palette, breakpoints } = useTheme();

	return (
		<Grid
			item
			xs={12}
			lg={6}
			position={'relative'}
			zIndex={1}
			bgcolor={content.background_color ? content.background_color : palette.secondary.main}
		>
			{content && (
				<Box
					position={'sticky'}
					top={'100px'}
					width={'100%'}
					display={'flex'}
					flexDirection={'column'}
					justifyContent={'space-between'}
					py={'48px'}
				>
					<Box
						display={'flex'}
						flexDirection={'column'}
						mx={'80px'}
						sx={{
							[breakpoints.down('md')]: {
								mx: '40px',
							},
						}}
					>
						{content.title && (
							<Typography
								variant='h3'
								fontWeight={'500'}
								textAlign={'center'}
								color={content.font_color && content.font_color}
								mb={'41px'}
							>
								{content.title}
							</Typography>
						)}
						{content.description && (
							<Typography
								variant={'subtitle1'}
								component={'div'}
								sx={{
									mb: '45px',
									fontWeight: '400',
									textAlign: 'center',
									color: content.font_color && content.font_color,
									'& *': {
										m: '0',
										p: '0',
									},
									'& ul': {
										padding: 0,
									},
									'& li': {
										margin: '20px 0 20px 10px',
									},
									[breakpoints.down('lg')]: {
										'& *': { whiteSpace: 'normal' },
									},
									[breakpoints.down('sm')]: {
										'& *': {
											whiteSpace: 'normal !important',
										},
									},
								}}
								dangerouslySetInnerHTML={{
									__html: cleanUpHtml(content.description),
								}}
							/>
						)}
						{content.highlighted_text && (
							<Link
								href={content.highlighted_text.link.url && content.highlighted_text.link.url}
								target={content.highlighted_text.link.target && content.highlighted_text.link.target}
								sx={{
									display: 'block',
									position: 'relative',
									textDecoration: 'none',
									'& *': {
										position: 'relative',
										zIndex: 3,
										wordBreak: 'break-word',
										[breakpoints.down('sm')]: {
											whiteSpace: 'normal',
										},
									},
								}}
							>
								<Box
									display={'flex'}
									justifyContent={'center'}
								>
									<Typography
										variant={'subtitle1'}
										sx={{
											fontWeight: '400',
											color: palette.primary.main,
											position: 'relative',
											textAlign: 'center',
											zIndex: 3,
											lineHeight: '26px',
											px: '32px',
											backgroundImage: `url(${getLineHighlight().long})`,
											backgroundSize: '100%',
											backgroundRepeat: 'round',
											backgroundPosition: '0 0',
											[breakpoints.down('sm')]: {
												color: content.font_color && content.font_color,
											},
										}}
									>
										{content.highlighted_text.text && content.highlighted_text.text}
										{content.highlighted_text.link.title && (
											<Box
												component={'span'}
												sx={{
													display: 'inline-block',
													textDecoration: 'underline',
												}}
											>
												{content.highlighted_text.link.title}
											</Box>
										)}
									</Typography>
								</Box>
							</Link>
						)}
					</Box>
					{content && (
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							sx={{
								mt: '96px',
								[breakpoints.down('lg')]: {
									mt: '72px',
								},
							}}
						>
							{previous && (
								<Box
									display={'flex'}
									flexDirection={'row-reverse'}
									alignItems={'center'}
									justifyContent={'flex-end'}
									gap={'16px'}
								>
									<Typography
										variant={'h4'}
										fontWeight={'500'}
										color={content.font_color && content.font_color}
										sx={{
											[breakpoints.down('sm')]: {
												display: 'none',
											},
										}}
									>
										{previous}
									</Typography>
									<PreviousPageArrow
										onClick={() => onStepChange(-1)}
										color={content.background_color && content.background_color}
									/>
								</Box>
							)}
							{next && (
								<Box
									display={'flex'}
									alignItems={'center'}
									justifyContent={'flex-end'}
									gap={'16px'}
									ml={'auto'}
								>
									<Typography
										variant={'h4'}
										fontWeight={'500'}
										color={content.font_color && content.font_color}
										sx={{
											[breakpoints.down('sm')]: {
												display: 'none',
											},
										}}
									>
										{next}
									</Typography>
									<NextPageArrow
										onClick={() => onStepChange(1)}
										color={content.background_color && content.background_color}
									/>
								</Box>
							)}
						</Box>
					)}
				</Box>
			)}
			<Box
				sx={{
					width: '100%',
					height: '100%',
				}}
			/>
		</Grid>
	);
};

export default RightPane;
