import { useContext, useState } from 'react';
import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import MainButton from '../UI/MainButton';
import HideInputIcon from '../UI/HideInputIcon';
import isEmail from 'validator/lib/isEmail';
import SnackbarContext from '../../store/snackbar-context';
import AuthContext from '../../store/auth-context';
import { sendResetLinkRequest } from '../../api/auth-api';
import FormsLayout from '../../layout/FormsLayout';
import { registrationPage } from '../../store/fixed-routing';
import DividerWithButton from '../UI/DividerWithButton';
import FormTextField from '../UI/FormTextField';

const formErrors = {
	emailError: 'Wprowadź poprawny email',
	passwordError: 'Wprowadź hasło',
};

const LoginForm = () => {
	const { palette, typography, breakpoints } = useTheme();
	const [showPassword, setShowPassword] = useState(false);
	const { login, role } = useContext(AuthContext);
	const [passwordReset, setPasswordReset] = useState(false);
	const [validated, setValidated] = useState(false);
	const { setMsg } = useContext(SnackbarContext);
	const [formInfo, setFormInfo] = useState({
		email: '',
		password: '',
	});

	const [formValidation, setFormValidation] = useState(formErrors);

	const emailHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				emailError: formErrors.emailError,
			});
		} else if (!isEmail(value)) {
			setFormValidation({
				...formValidation,
				emailError: 'Niepoprawny adres e-mail',
			});
		} else {
			setFormValidation({
				...formValidation,
				emailError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				email: value,
			};
		});
	};

	const passwordHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				passwordError: formErrors.passwordError,
			});
		} else {
			setFormValidation({
				...formValidation,
				passwordError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				password: value,
			};
		});
	};

	const actionSend = (event: { preventDefault: () => void }) => {
		setValidated(true);
		event.preventDefault();
		if (passwordReset) {
			if (formValidation.emailError !== '') {
				setMsg({ msg: formValidation.emailError, severity: 'warning' });
				return;
			}
			sendResetLinkRequest(formInfo.email, (error) => console.log(error))
				.then((res) => {
					setMsg({ msg: 'Sprawdź swoją skrzynkę pocztową', severity: 'info' });
				})
				.catch((error) =>
					setMsg({ msg: 'Wystąpił błąd przy resetowaniu hasła. Spróbuj ponownie później.', severity: 'error' }),
				);
		} else {
			if (formValidation.emailError !== '') {
				setMsg({ msg: formValidation.emailError, severity: 'warning' });
				return;
			}
			if (formValidation.passwordError !== '') {
				setMsg({ msg: formValidation.passwordError, severity: 'warning' });
				return;
			}
			login(formInfo.email, formInfo.password);
		}
	};

	return (
		<FormsLayout>
			<Grid
				width={'100%'}
				maxWidth={'650px'}
				display={'flex'}
				flexDirection={'column'}
				gap={'24px'}
			>
				<Typography
					variant={'h3'}
					fontWeight={'500'}
					color={palette.primary.main}
				>
					{!passwordReset ? 'Zaloguj się' : 'Resetuj hasło'}
				</Typography>
				<Grid
					component={'form'}
					display={'flex'}
					flexDirection={'column'}
				>
					<Box
						display={'flex'}
						flexDirection={'column'}
						alignItems={'flex-start'}
						gap={'4px'}
						width={'100%'}
						mb={'16px'}
					>
						<FormTextField
							id={'email'}
							type={'email'}
							label={'E-mail'}
							validated={validated}
							errorMsg={formValidation.emailError}
							value={formInfo.email}
							valueChangeHandler={emailHandler}
						/>
					</Box>
					{!passwordReset && (
						<FormTextField
							id={'password'}
							type={!showPassword ? 'password' : ''}
							label={'Hasło'}
							validated={validated}
							errorMsg={formValidation.passwordError}
							value={formInfo.password}
							valueChangeHandler={passwordHandler}
							endIconElement={
								<IconButton
									sx={{
										padding: 0,
										'& svg': {
											minHeight: '30px',
											minWidth: '30px',
										},
									}}
									onClick={() => {
										setShowPassword(!showPassword);
									}}
								>
									<HideInputIcon />
								</IconButton>
							}
						/>
					)}
					<Typography
						sx={{
							fontSize: typography.h6.fontSize,
							fontWeight: '400',
							textDecoration: 'underline',
							alignSelf: 'flex-end',
							color: palette.primary.main,
							cursor: 'pointer',
							mt: '4px',
							mb: '32px',
							[breakpoints.down('md')]: {
								fontSize: `${typography.h5.fontSize}`,
							},
						}}
						onClick={() => {
							setPasswordReset(!passwordReset);
						}}
					>
						{!passwordReset ? 'Nie pamiętasz hasła?' : 'Zaloguj się'}
					</Typography>
					<Box
						alignSelf={'center'}
						mt={!passwordReset ? '0' : '16px'}
						onClick={actionSend}
					>
						<MainButton
							title={!passwordReset ? 'Zaloguj się' : 'Wyślij'}
							darkVersion={true}
							sx={{
								alignSelf: 'center',
								lineHeight: '20px',
								letterSpacing: '0.18px',
							}}
						/>
					</Box>
				</Grid>
			</Grid>
			<DividerWithButton
				label={'Nie masz konta?'}
				hrefPath={registrationPage}
				buttonTitle={'Zarejestruj się'}
			/>
		</FormsLayout>
	);
};

export default LoginForm;
