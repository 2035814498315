class Schools {
	id: string;
	name: string;
	olympicsID: string;
	districtID: string;
	created: string;
	updated: string;
	city: string;
	zipCode: string;
	street: string;
	building: string;
	apartment: string;

	constructor(
		id: string,
		name: string,
		olympicsID: string,
		districtID: string,
		created: string,
		updated: string,
		city: string,
		zipCode: string,
		street: string,
		building: string,
		apartment: string,
	) {
		this.id = id;
		this.name = name;
		this.olympicsID = olympicsID;
		this.districtID = districtID;
		this.created = created;
		this.updated = updated;
		this.city = city;
		this.zipCode = zipCode;
		this.street = street;
		this.building = building;
		this.apartment = apartment;
	}

	public static fromApiResponse(responses: any) {
		return responses.map(
			(response: any) =>
				new Schools(
					response.id,
					response.name,
					response.olympics_id,
					response.district_id,
					response.created_at,
					response.updated_at,
					response.city,
					response.zip_code,
					response.street,
					response.building,
					response.apartment,
				),
		);
	}
}

export default Schools;
