import { createTheme } from '@mui/material/styles';
import { Palette as MuiPallete, PaletteOptions as MuiPaletteOptions } from '@mui/material/styles/createPalette';
import { olijpTheme } from './olijp-theme';
import { olijpspTheme } from './olijpsp-theme';
import line from '../ui/images/Line.png';
import line2 from '../ui/images/Line2.png';
import lineorange from '../ui/images/Lineorange.png';
import line2orange from '../ui/images/Line2orange.png';

declare module '@mui/material/styles/createPalette' {
	// @ts-ignore
	interface Palette extends MuiPallete {
		customColors: {
			main: string;
			gray: string;
		};
	}

	// @ts-ignore
	interface PaletteOptions extends MuiPaletteOptions {
		customColors?: {
			main: string;
			gray: string;
		};
	}
}

declare module '@mui/material/styles' {
	interface TypographyVariants {
		title: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		title?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		title: true;
	}
}

export const getLineHighlight = () => {
	switch (process.env.REACT_APP_NAME) {
		case 'OLiJPSP':
			return { long: lineorange, short: line2orange };
		case 'OLiJP':
			return { long: line, short: line2 };
		default:
			return { long: line, short: line2 };
	}
};
const getTheme = () => {
	switch (process.env.REACT_APP_NAME) {
		case 'OLiJPSP':
			return olijpspTheme;
		case 'OLiJP':
			return olijpTheme;
		default:
			return olijpTheme;
	}
};

// @ts-ignore
const theme = createTheme(getTheme());
export default theme;
