import Student from '../../model/Student';
import {Collapse, Divider, Grid, Typography, useTheme} from '@mui/material';
import React, {useState} from 'react';
import {UserDataText} from './UserPanel';
import {GridProps} from '@mui/material/Grid/Grid';
import Teacher from '../../model/Teacher';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Row = (props: GridProps) => {
    return (
        <Grid
            item
            container
            justifyContent={'space-between'}
            {...props}
        >
            {props.children}
        </Grid>
    );
};

type Props = {
    student: Student | null;
};
const UserData = ({student}: Props) => {
    const {palette} = useTheme();
    const [expand, setExpand] = useState(false);

    return (
        <>
            <Grid
                item
                container
                justifyContent={'space-between'}
                mt={5}
                onClick={() => setExpand(!expand)}
                sx={{cursor: 'pointer'}}
            >
                <Typography
                    variant={'h4'}
                    fontWeight={'500'}
                    color={palette.primary.main}
                >
                    Twoje dane
                </Typography>
                {
                    expand ? <KeyboardArrowUpIcon sx={{color: palette.primary.main}}/> :
                        <KeyboardArrowDownIcon sx={{color: palette.primary.main}}/>
                }
            </Grid>
            <Divider
                variant={'fullWidth'}
                sx={{
                    backgroundColor: palette.secondary.light,
                    borderBottomWidth: '3px',
                }}
            />
            <Collapse
                in={expand}
            >
                <Grid
                    item
                    container
                    justifyContent={'start'}
                    width={'100%'}
                    gap={'24px'}
                >
                    <Row>
                        <UserDataText
                            title={'Imię i nazwisko: '}
                            text={`${student?.personalTitle} ${student?.firstName} ${student?.lastName}`}
                        />
                    </Row>
                    <Row>
                        <UserDataText
                            title={'Adres: '}
                            text={`${student?.street} ${student?.building} ${student?.apartment}, ${student?.zipCode} ${student?.city}`}
                        />
                    </Row>
                    <Row>
                        <UserDataText
                            title={'Szkoła: '}
                            text={`${student?.school.name} ${student?.school.city}`}
                        />
                        <UserDataText
                            title={'Klasa: '}
                            text={`${student?.schoolClass}`}
                        />
                    </Row>
                    <Row>
                        <UserDataText
                            title={'Specjalizacja: '}
                            text={`${student?.specialization.name}`}
                        />
                        <UserDataText
                            title={'Opiekunowie: '}
                            text={`${student?.teachers.map((t: Teacher) => `${t.firstName} ${t.lastName}`).join(', ')}`}
                        />
                    </Row>
                </Grid>
            </Collapse>
        </>
    );
};

export default UserData;
