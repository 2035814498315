import { useEffect, useState } from 'react';
import { Box, Grid, Typography, useTheme, Divider, Button } from '@mui/material';
import Wysiwyg from '../components/UI/Wysiwyg';
import NewsTile from '../components/NewsTile';
import ArrowRight from '../ui/icons/ArrowRight';
import ArrowLeft from '../ui/icons/ArrowLeft';
import { getAnnouncementsPerPage, getPostsPerPage } from '../api/content-api';

const ArticleNewsList = ({ content }: any) => {
	const { palette } = useTheme();
	const totalPages = content.list.total_pages;
	const perPage = content.list.per_page;
	const ALL_POSTS = 'Wszystko';
	const allPosts = content.list.posts;
	const [currentPage, setCurrentPage] = useState(0);
	const [downloadPage, setDownloadPage] = useState(2);
	const [posts, setPosts] = useState<any[]>(allPosts);
	const [displayedPosts, setDisplayedPosts] = useState<any[]>();
	const [currentCategory, setCurrentCategory] = useState<string>(ALL_POSTS);
	const [maximumPages, setMaximumPages] = useState(1);
	const [activeCategory, setActiveCategory] = useState<string>(ALL_POSTS);

	const filterByCategory = (posts: any[], category: string) => {
		if (category === ALL_POSTS) {
			return posts;
		} else {
			return posts.filter((a: any) => a.taxonomy.includes(category));
		}
	};

	const addHashTagToUrl = (category: string) => {
		const currentURL = window.location.href;
		let newURL = currentURL.replace(/#(.*)$/, '');
		if (category && category !== 'ALL_POSTS') {
			newURL = newURL + '#' + category;
		}
		window.history.pushState(null, '', newURL);
	};

	useEffect(() => {
		if (currentCategory) {
			addHashTagToUrl(currentCategory);
			const startIndex = perPage * currentPage;
			const endIndex = perPage * currentPage + perPage;
			let displayedPosts = filterByCategory(posts, currentCategory);
			const pagedPosts = displayedPosts.slice(startIndex, endIndex);
			setDisplayedPosts(pagedPosts);
			setMaximumPages(Math.ceil(displayedPosts.length / perPage));
		}
	}, [currentCategory, currentPage, posts]);

	useEffect(() => {
		if (totalPages !== 1 && downloadPage <= totalPages && typeof window !== 'undefined') {
			content.list.type === 'post'
				? getPostsPerPage(perPage, downloadPage)
						.then((response) => {
							const newPosts = [...posts, ...response.posts];
							setPosts(newPosts);
							setDownloadPage(downloadPage + 1);
						})
						.catch((error) => {
							console.log(error);
						})
				: getAnnouncementsPerPage(perPage, downloadPage)
						.then((response) => {
							const newPosts = [...posts, ...response.posts];
							setPosts(newPosts);
							setDownloadPage(downloadPage + 1);
						})
						.catch((error) => {
							console.log(error);
						});
		}
	}, [posts]);

	if (currentPage >= maximumPages) {
		setCurrentPage(maximumPages - 1);
	}

	useEffect(() => {
		setCurrentPage(0);
	}, [currentCategory]);

	return (
		<Grid
			component='section'
			container
			justifyContent={'center'}
			pt='27px'
			pb='62px'
		>
			<Grid
				item
				xs={12}
				lg={11.4}
				px={{ xs: '26px', lg: 0 }}
			>
				<Wysiwyg
					text={content.list_title && content.list_title}
					fontWeight={'500'}
					variantSize={'h3'}
					sx={{
						display: 'flex',
						alignItems: 'left',
						justifyContent: { xs: 'center', md: 'left' },
						pb: '24px',
						letterSpacing: '0.32px',
						margin: '0px',
					}}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				lg={11.4}
				px={{ xs: '26px', lg: 0 }}
			>
				<Divider sx={{ bgcolor: palette.primary.main }} />
				<Grid
					item
					container
					p={'1px 16px'}
					justifyContent={'space-start'}
					flexDirection={{ xs: 'column', md: 'row' }}
					alignItems={{ xs: 'center', md: 'left' }}
					sx={{
						maxWidth: '100%',
						flexWrap: 'wrap',
					}}
				>
					<Typography
						py='17px'
						fontSize='16px'
						letterSpacing='0.16px'
						textTransform='uppercase'
						color={palette.primary.main}
						fontWeight={activeCategory === ALL_POSTS ? '600' : '400'}
						sx={{
							cursor: 'pointer',
							mx: '10px',
							textDecoration: activeCategory === ALL_POSTS ? 'underline' : '',
						}}
						onClick={() => {
							setCurrentCategory(ALL_POSTS);
							setActiveCategory(ALL_POSTS);
						}}
					>
						{ALL_POSTS}
					</Typography>
					{content.list.categories.map((item: any, index: number) => (
						<Typography
							key={index}
							py='17px'
							fontSize='16px'
							letterSpacing='0.16px'
							textTransform='uppercase'
							color={palette.primary.main}
							fontWeight={activeCategory === item ? '600' : '400'}
							sx={{
								mx: '10px',
								textDecoration: activeCategory === item ? 'underline' : '',
								whiteSpace: 'nowrap',
								cursor: 'pointer',
							}}
							onClick={() => {
								setCurrentCategory(item);
								setActiveCategory(item);
							}}
						>
							{item}
						</Typography>
					))}
				</Grid>
				<Divider sx={{ bgcolor: palette.primary.main }} />
			</Grid>
			<Grid
				container
				item
				xs={12}
				lg={12}
				gap={'20px'}
				justifyContent={'center'}
				p={'80px 40px'}
			>
				{displayedPosts &&
					displayedPosts.map((item: any, index: number) => {
						return (
							<Grid
								item
								key={index}
								sx={{
									flex: '0 0 250px',
								}}
							>
								<NewsTile item={item} />
							</Grid>
						);
					})}
			</Grid>
			<Grid
				container
				item
				xs={12}
				lg={11.4}
				mt='64px'
				px={{ xs: '26px', lg: 0 }}
			>
				<Box
					display={maximumPages < 1 ? 'none' : 'flex'}
					justifyContent={'flex-end'}
					width={'100%'}
					my={'40px'}
				>
					<Box
						flexDirection={'column'}
						alignItems={'flex-start'}
						gap={'13px'}
					>
						<Divider
							flexItem
							sx={{ bgcolor: palette.primary.main }}
						/>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
							sx={{ gap: { sm: '111px' } }}
						>
							<Box
								display={'flex'}
								alignItems={'flex-start'}
								gap={{ sm: '24px' }}
								sx={{
									'& *': {
										cursor: 'pointer',
									},
								}}
							>
								<Button
									sx={{
										borderRadius: '0',
										width: '48px',
										height: '64px',
										'& * path': {
											fill: `${palette.primary.main}`,
										},
									}}
									onClick={() => {
										currentPage !== 0 ? setCurrentPage(currentPage - 1) : setCurrentPage(maximumPages);
									}}
								>
									<ArrowLeft />
								</Button>
								<Button
									sx={{
										borderRadius: '0',
										width: '48px',
										height: '64px',
										'& * path': {
											fill: `${palette.primary.main}`,
										},
									}}
									onClick={() => {
										currentPage + 1 < maximumPages ? setCurrentPage(currentPage + 1) : setCurrentPage(0);
									}}
								>
									<ArrowRight />
								</Button>
							</Box>
							<Box
								display={'flex'}
								alignItems={'center'}
								p={'0px'}
							>
								<Typography
									letterSpacing={'2px'}
									sx={{
										fontWeight: '500',
										fontSize: '22px',
										lineHeight: '24px',
										textTransform: 'uppercase',
										color: palette.primary.main,
									}}
								>
									{(currentPage + 1).toString().padStart(2, '0')}
								</Typography>
								<Typography
									fontSize={'26px'}
									letterSpacing={'2px'}
									fontWeight={'200'}
									sx={{
										transform: 'translateY(1px)',
									}}
								>
									/
								</Typography>
								<Typography
									sx={{
										fontWeight: '500',
										fontSize: '16px',
										lineHeight: '17px',
										textTransform: 'uppercase',
										color: palette.primary.main,
									}}
								>
									{maximumPages.toString().padStart(2, '0')}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};

export default ArticleNewsList;
