import Block from './Block';
import { getRelativeLink } from '../utils/string-tools';
import { Route } from 'react-router-dom';
import MainLayout from '../layout/MainLayout';
import getBlocks from '../blocks/getBlocks';

export type Breadcrumb = {
	title: string;
	link: string;
};
class Page {
	link: string;
	title: string;
	breadcrumbs: Breadcrumb[];
	blocks: Block[];

	constructor(link: string, title: string, breadcrumbs: Breadcrumb[], blocks: Block[]) {
		this.link = link;
		this.title = title;
		this.breadcrumbs = breadcrumbs;
		this.blocks = blocks;
	}

	public static fromApiResponse(data: any) {
		const pages = [] as Page[];
		data.forEach((d: any) => {
			if (d.acf.blocks) {
				const blocks = d.acf.blocks.map((block: any) => new Block(block.acf_fc_layout, block));
				pages.push(new Page(getRelativeLink(d.link), d.post_title, d.breadcrumbs, blocks));
			}
		});
		return pages;
	}

	public toRoute() {
		const blocks: any = getBlocks(this.blocks);
		return (
			<Route
				key={this.title}
				path={this.link}
				element={<MainLayout>{blocks}</MainLayout>}
			/>
		);
	}
}

export default Page;
