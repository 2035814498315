import { useContext, useEffect, useState } from 'react';
import FormsLayout from '../../../layout/FormsLayout';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import MainButton from '../../UI/MainButton';
import RegisterTeacher from './RegisterTeacher';
import RegisterStudent from './RegisterStudent';
import SnackbarContext from '../../../store/snackbar-context';
import { getRegistrationSettings } from '../../../api/content-api';

const RegisterForm = () => {
	const { palette } = useTheme();
	const [form, setForm] = useState<any>(undefined);
	const [registrationSettings, setRegistrationSettings] = useState({
		studentsRegistration: false,
		teachersRegistration: false,
	});
	const { setMsg } = useContext(SnackbarContext);
	const msg = 'Rejestracja jest aktualnie zamknięta';

	useEffect(() => {
		getRegistrationSettings()
			.then((response) => {
				if (response.status === 200) {
					setRegistrationSettings(response.data);
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
			})
			.catch((exception) => {
				console.error(exception);
				setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
			});
	}, []);

	return (
		<FormsLayout>
			{form === undefined && (
				<Grid
					width={'100%'}
					maxWidth={'500px'}
					display={'flex'}
					flexDirection={'column'}
					gap={'48px'}
				>
					<Typography
						variant={'h3'}
						fontWeight={'500'}
						color={palette.primary.main}
						textAlign={'center'}
					>
						Zarejestruj się jako:
					</Typography>
					<Box
						display={'flex'}
						gap={'128px'}
						justifyContent={'center'}
					>
						<Box
							onClick={() => {
								!registrationSettings.teachersRegistration ? setMsg({ msg }) : setForm('teacher');
							}}
						>
							<MainButton
								title={'Nauczyciel'}
								disabled={!registrationSettings.teachersRegistration}
							/>
						</Box>
						<Box
							onClick={() => {
								!registrationSettings.studentsRegistration ? setMsg({ msg }) : setForm('student');
							}}
						>
							<MainButton
								title={'Uczeń'}
								disabled={!registrationSettings.studentsRegistration}
							/>
						</Box>
					</Box>
				</Grid>
			)}
			{form === 'teacher' ? <RegisterTeacher /> : form === 'student' && <RegisterStudent />}
		</FormsLayout>
	);
};

export default RegisterForm;
