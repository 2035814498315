import { Divider, Grid, Typography, useTheme } from '@mui/material';
import MainButton from './MainButton';
import { useNavigate } from 'react-router-dom';

type Props = {
	label?: string;
	hrefPath: any;
	buttonTitle: string;
};

const DividerWithButton = ({ label, hrefPath, buttonTitle }: Props) => {
	const { palette } = useTheme();
	return (
		<Grid
			width={'100%'}
			maxWidth={'650px'}
			display={'flex'}
			flexDirection={'column'}
			gap={'24px'}
		>
			<Divider
				sx={{
					'&:before, &:after': {
						borderColor: palette.primary.main,
					},
				}}
			>
				{label && (
					<Typography
						component={'span'}
						variant={'subtitle2'}
						fontWeight={'500'}
						color={palette.primary.main}
						sx={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						{label}
					</Typography>
				)}
			</Divider>
			<MainButton
				title={buttonTitle}
				href={hrefPath}
				sx={{
					alignSelf: 'center',
					lineHeight: '20px',
					letterSpacing: '0.18px',
				}}
			/>
		</Grid>
	);
};

export default DividerWithButton;
