class Districts {
	id: string;
	name: string;
	olympicsID: string;
	created: string;
	updated: string;

	constructor(id: string, name: string, olympicsID: string, created: string, updated: string) {
		this.id = id;
		this.name = name;
		this.olympicsID = olympicsID;
		this.created = created;
		this.updated = updated;
	}

	public static fromApiResponse(responses: any) {
		const districts = [] as Districts[];
		responses.map((response: any) => {
			response &&
				districts.push(
					new Districts(response.id, response.name, response.olympics_id, response.created_at, response.updated_at),
				);
		});
		return districts;
	}
}

export default Districts;
