import { ReactNode } from 'react';
import { Grid } from '@mui/material';
import CustomSnackbar from '../components/UI/CustomSnackbar';

const PanelLayout = ({ children }: { children: ReactNode }) => {
	return (
		<>
			<Grid
				item
				container
				component={'main'}
				sx={{
					width: '100vw',
					height: '100vh',
				}}
			>
				{children}
			</Grid>
			<CustomSnackbar />
		</>
	);
};

export default PanelLayout;
